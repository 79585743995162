import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Header = ({ title, img, description }) => {
  return (
    <Row>
      <Col className="bg-infox d-flex flex-column align-items-center">
        {/* Report Img */}
        <img alt="img" src={img} width="150px" height="150px" />
        <h1 className="display-4">{title}</h1>
        {/* Description */}
        {/* style={{ whiteSpace: 'pre-wrap' }} */}
        <div className="bg-successx pre-wrap  w-100 mt-5">{description}</div>
      </Col>
    </Row>
  );
};

export default Header;
