import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const InputEmail = ({ label, value, placeholder, isRequired, helpText, setObj, setErrObj }) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}
      <InputGroup>
        <InputGroup.Text>
          <CsLineIcons icon="email" size="16" className="text-alternate" />
        </InputGroup.Text>
        <Form.Control
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={(e) => {
            setObj((prevValues) => {
              return { ...prevValues, email: e.target.value };
            });
            setErrObj((prevErrors) => {
              return { ...prevErrors, email: '' };
            });
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
    </>
  );
};

export default InputEmail;
