import React from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import InputText from '../../../../components/form-elements/InputText2';
import SelectFile from '../../../../components/form-elements/SelectFile';

const PackageInfoDamaged = ({ values, setValues, errors, setErrors }) => {
  return (
    <Row className="g-3 mb-5">
      <h2 className="small-title">Información Paquete</h2>
      <div>
        Es crucial proporcionar la información correcta sobre el contenido, la cual debe coincidir con el comprobante entregado a la paquetería y la evidencia
        disponible.
      </div>
      <Col sm="12" className="position-relative">
        <InputText
          label="Describe el empaque o embalaje exterior y detalla cualquier daño que presente (si lo hay)."
          icon="box"
          maxLength=""
          placeholder=""
          value={values.packagingDescription}
          isRequired="true"
          helpText=""
          fieldName="packagingDescription"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packagingDescription && (
          <div className={`invalid-tooltip ${errors.packagingDescription ? 'd-block' : 'd-none'}`}>{errors.packagingDescription}</div>
        )}
      </Col>

      <Col sm="12" className="position-relative">
        <InputText
          label="Indica el tipo de embalaje interno (protección) y detalla cualquier daño que presente (si lo hay)."
          icon="box"
          maxLength=""
          placeholder=""
          value={values.packagingInternalDescription}
          isRequired="true"
          fieldName="packagingInternalDescription"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packagingInternalDescription && (
          <div className={`invalid-tooltip ${errors.packagingInternalDescription ? 'd-block' : 'd-none'}`}>{errors.packagingInternalDescription}</div>
        )}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="Describe el contenido y detalla exactamente el daño que presenta (si lo hay)."
          icon="box"
          maxLength=""
          placeholder=""
          value={values.packageContent}
          isRequired="true"
          fieldName="packageContent"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packageContent && <div className={`invalid-tooltip ${errors.packageContent ? 'd-block' : 'd-none'}`}>{errors.packageContent}</div>}
      </Col>
      <Col sm="6" className="position-relative">
        <InputText
          label="¿Cuántas piezas llegaron dañadas? "
          icon="info-hexagon"
          maxLength="50"
          placeholder=""
          value={values.missingParts}
          isRequired="true"
          fieldName="missingParts"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.missingParts && <div className={`invalid-tooltip ${errors.missingParts ? 'd-block' : 'd-none'}`}>{errors.missingParts}</div>}
      </Col>
      <Col sm="6" className="position-relative">
        <InputText
          // label="¿El daño en el empaque exterior coincide con el daño en el contenido?"
          label="Daño exterior e interior"
          icon="info-hexagon"
          maxLength=""
          placeholder="¿Se presenta en el exterior e interior?"
          value={values.damagedDescription}
          isRequired="true"
          fieldName="damagedDescription"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.damagedDescription && <div className={`invalid-tooltip ${errors.damagedDescription ? 'd-block' : 'd-none'}`}>{errors.damagedDescription}</div>}
      </Col>
      <Col sm="6" className="position-relative">
        <InputText
          label="¿Es posible reparar el contenido?"
          icon="info-hexagon"
          maxLength=""
          placeholder=""
          value={values.isRepairable}
          isRequired="true"
          fieldName="isRepairable"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.isRepairable && <div className={`invalid-tooltip ${errors.isRepairable ? 'd-block' : 'd-none'}`}>{errors.isRepairable}</div>}
      </Col>
      <Col sm="6" className="position-relative">
        <InputText
          label="¿Cuál es el costo o la cotización para la reparación?"
          icon="info-hexagon"
          maxLength=""
          placeholder=""
          value={values.repairCost}
          isRequired="true"
          fieldName="repairCost"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.repairCost && <div className={`invalid-tooltip ${errors.repairCost ? 'd-block' : 'd-none'}`}>{errors.repairCost}</div>}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="Por favor, indica si el paquete presenta cinta adhesiva con rotulación diferente a la original."
          icon="info-hexagon"
          maxLength=""
          placeholder=""
          value={values.packageTape}
          isRequired="true"
          fieldName="packageTape"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packageTape && <div className={`invalid-tooltip ${errors.packageTape ? 'd-block' : 'd-none'}`}>{errors.packageTape}</div>}
      </Col>
      <Col sm="9" className="position-relative mt-5">
        <SelectFile
          label="Adjunta tu factura del contenido"
          placeholder="Sube o arrastra tu factura"
          isRequired="true"
          fieldName="invoiceFile"
          formObj={setValues}
          setErrors={setErrors}
          helpText={`La descripción del contenido debe coincidir con la del comprobante y la fecha del comprobante debe ser anterior o igual al día en que creaste tu guía.
      *No se aceptan capturas de transferencias ni tickets de depósitos.`}
        />
        {errors.invoiceFile && <div className={`invalid-tooltip ${errors.invoiceFile ? 'd-block' : 'd-none'}`}>{errors.invoiceFile}</div>}
      </Col>
    </Row>
  );
};

export default PackageInfoDamaged;
