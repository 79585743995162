import React from 'react';
import { CSVLink } from 'react-csv';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// external libraries
import moment from 'moment';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const StatementsCSV = ({ statements, crmId }) => {
  const csvData = statements.map((statement) => ({
    date: moment(statement.created_date).format('DD-MM-YY'),
    time: statement.created_date.slice(11, 16),
    concept: statement.statement_text.toUpperCase(),
    reference: statement.reference,
    // reference: typeof statement?.reference === 'string' ? statement?.reference.toUpperCase() : statement?.reference,
    balance: fmDecimal.from(statement.current_balance, { symbol: '$' }),
    monto: fmDecimal.from(statement.amount, { symbol: '$' }),
    ...statement,
  }));

  const headers = [
    // { label: 'ID', key: 'id' },
    { label: 'Fecha', key: 'date' },
    { label: 'Hora', key: 'time' },
    { label: 'Concepto', key: 'concept' },
    { label: 'Referencia', key: 'reference' },
    { label: 'Monto', key: 'monto' },
    { label: 'Saldo', key: 'balance' },
  ];
  // console.log("excel movimientos",csvData)
  return (
    <CSVLink
      data={csvData}
      headers={headers}
      className="btn btn-primary"
      filename={`Historial Movimientos ${crmId}.csv`}
      target="_blank"
      style={{ height: '36px' }}
    >
      <span>
        <CsLineIcons icon="download" size="15" className="mb-1" /> Excel{' '}
      </span>
    </CSVLink>
  );
};

export default StatementsCSV;
