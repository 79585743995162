import axios from 'axios';

// REACT_APP_API_V1=https://api.autopaquete.com.mx/v1
// POST
const apiV1AxiosInstanceLogin = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
  timeout: 10000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    accept: '*/*',
  },
});
// GET
const msZipCodeAxiosInstance = axios.create({
  baseURL: `https://postalcodes.autopaquete.com.mx/coberturas/detalles-codigo-postal`,
  timeout: 10000,
  responseType: 'json',
  headers: {
    Authorization: 'Token 70cb427de4630ca4d8d2447fafb359700539e306',
    'Content-Type': 'application/json',
  },
});

// GET
const apiV1AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
  timeout: 10000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json', // Enviamos datos en JSON
    accept: '*/*',
    //  Accept: 'application/json', // Solicitamos una respuesta en JSON
  },
});
const apiV1AxiosInstanceForm = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
  timeout: 10000,
  responseType: 'json',
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: '*/*',
  },
});
const getToken = () => {
  // const token = JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key;
  const token = JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key_production;
  // console.log('token', token);
  return token;
};
// Agregar un interceptor de solicitudes para actualizar el token antes de cada solicitud
apiV1AxiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
apiV1AxiosInstanceForm.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export { apiV1AxiosInstance, apiV1AxiosInstanceLogin, msZipCodeAxiosInstance, apiV1AxiosInstanceForm };
