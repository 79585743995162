import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { updateFormField, deleteFormError } from '../../utils/updateFormField';
// props
// label, icon, maxLength, placeholder, value, isRequired, helpText
// fieldName, validation, formObj, setErrors
// actualizar este componente para que muestre los erores
// {errors.packageTape && <div className={`invalid-tooltip ${errors.packageTape ? 'd-block' : 'd-none'}`}>{errors.packageTape}</div>}
const InputText = ({ label, icon, maxLength, placeholder, value, isRequired, helpText, fieldName, validation, formObj, setErrors }) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}
      <InputGroup>
        <InputGroup.Text>
          <CsLineIcons icon={icon} size="16" className="text-alternate" />
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            // console.log('datos para setear el obj:', formObj, e.target.value, validation, fieldName);
            updateFormField(formObj, e.target.value, validation, fieldName);
            deleteFormError(setErrors, fieldName);
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
    </>
  );
};

export default InputText;
