import React from 'react';
import { Row, Col } from 'react-bootstrap';

const OverweightRow = () => {
  return (
    <Row className="g-0 h-100 px-4 mb-2 align-content-center d-none d-lg-flex align-items-center custom-sort">
      <Col md="2" className="d-flex flex-column mb-lg-0 pe-3">
        <div className="text-muted text-small cursor-pointer">FECHA</div>
      </Col>
      <Col md="2" className="d-flex flex-column pe-1 ps-lg-4 justify-content-center align-items-center bg-infow">
        <div className="text-muted text-small cursor-pointeR">PESO REAL</div>
      </Col>
      <Col md="2" className="d-flex flex-column ps-4 pe-1 justify-content-center align-items-center bg-successw">
        <div className="text-muted text-small cursor-pointeR">PESO DECLARADO</div>
      </Col>
      <Col md="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
        <div className="text-muted text-small" />
      </Col>
      <Col md="3" className="d-flex flex-column pe-1 justify-content-center align-items-start">
        <div className="text-muted text-small cursor-pointer">ESTADO</div>
      </Col>
      <Col md="2" className="d-flex flex-column pe-1 justify-content-center align-items-end">
        <div className="text-muted text-small cursor-pointer">CARGO</div>
      </Col>
    </Row>
  );
};

export default OverweightRow;
