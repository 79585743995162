import React from 'react';
import { Col, Card } from 'react-bootstrap';
// external libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});
// const fm = new FormatMoney({
//   decimals: 0,
// });

const CounterCard = ({ icon, title, counter }) => {
  return (
    <Col xs="6" md="4" lg="3">
      <Card className="h-100 hover-scale-up cursor-pointer">
        <Card.Body className="d-flex flex-column align-items-center px-0">
          <div className="sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center border border-primary mb-4">
            <CsLineIcons icon={icon} className="text-primary" />
          </div>
          <div className="mb-1 d-flex align-items-center text-alternate text-small lh-1-25 text-center">{title.toUpperCase()}</div>
          {title === 'saldo actual' ? (
            <div className="text-primary text-medium font-weight-bold">{fmDecimal.from(counter, { symbol: '$' })}</div>
          ) : (
            <div className="text-primary text-medium font-weight-bold">{counter}</div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};
export default CounterCard;
