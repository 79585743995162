import React from 'react';
import { Form } from 'react-bootstrap';

const AutoFill = ({ label, value }) => (
  <>
    <Form.Label>{label}</Form.Label>
    <div className="small-title">{value}</div>
  </>
);

export default AutoFill;
