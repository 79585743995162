import React from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';

// External libraries
const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const CardShipping = ({ shipping }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
        <Col xs="auto" className="position-relative">
          <NavLink to={`/envios/historial/detalle/${shipping?.shipping_label_id}`}>
            <img src={shipping?.service_img} alt="product" className="card-img card-img-horizontal sw-11 h-100" />
          </NavLink>
        </Col>
        <Col className="py-4 py-lg-0 ps-5 pe-4 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="11" lg="3" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center">
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
                <NavLink className="anchor-effect-primary" to={`/envios/historial/detalle/${shipping?.shipping_label_id}`}>
                  {shipping.shipping_code?.replace(/_+/g, ' ')}
                </NavLink>
              </OverlayTrigger>
              <div className="text-medium text-muted text-truncate">ID: {shipping?.shipping_label_id}</div>
              <div className="text-small text-muted text-truncate">{shipping.address_to?.name}</div>
            </Col>
            <Col xs="6" lg="3" className="pe-1 mb-2 mb-lg-0 d-flex flex-column justify-content-center order-3">
              <div className="text-muted text-small d-lg-none mb-1">Fecha / Hora </div>

              <div className="lh-1 text-alternate">{`${moment(shipping.creation_date).format('DD-MM-YY')} ${shipping?.creation_date.slice(11, 16)}`}</div>
            </Col>
            <Col xs="6" lg="3" className="pe-1 mb-2 mb-lg-0 d-flex flex-column justify-content-center align-items-end align-items-lg-center order-4">
              <div className="text-muted text-small d-lg-none mb-1">Costo</div>
              <div className="lh-1 text-alternate">
                <span className="text-small text-muted">$</span>
                {fmDecimal.from(shipping.shipping_rates?.total)}
              </div>
            </Col>
            <Col xs="12" lg="2" className="pe-1 mb-2 mb-lg-0 d-flex flex-column justify-content-center align-items-end align-items-lg-center order-5">
              <div className="text-muted text-small d-lg-none mb-1">Peso cobrado</div>
              <div className="lh-1 text-alternate">
                {shipping.parcel?.total_weight}
                <span className="text-small text-muted"> kg</span>
              </div>
            </Col>
            <Col xs="1" className="mb-2 mb-lg-0 d-flex flex-column justify-content-lg-center align-items-end order-2 order-lg-last">
              {shipping?.shipping_label_id !== 'error' && (
                <a href={`data:application/pdf;base64,${shipping.label}`} download={shipping?.shipping_label_id} className="anchor-effect-primary">
                  <CsLineIcons icon="download" />
                </a>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default CardShipping;
