import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import UserCard from './components/UserCard';
import Paginate from '../../components/Paginate';
// import Loading from '../../../components/loading/Loading';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import AdminLoader from '../../../components/loading/AdminLoader';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';

// api
import { getUserAccounts } from '../../api/Api-admin';

const Users = () => {
  const title = 'Usuarios';
  const description = 'Usuarios Plataforma Autopaquete';
  // -Local States
  // const [users, setUsers] = useState(null);
  const [users, setUsers] = useState([]);

  const [identifierUser, setIdentifierUser] = useState('');
  // paginate states
  const [currentPage, setCurrentPage] = useState(1); // for the view
  const [pageSize, setPageSize] = useState(10); // for the view
  const [changePage, setChangePage] = useState(1); // for the request
  const [changePageSize, setChangePageSize] = useState(10); // for the request

  const [totalPages, setTotalPages] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  // const [paginateRender, setPaginateRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoader, setIsInitialLoader] = useState(true);
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // Get Shippings
  const handlerGetUsers = async () => {
    try {
      setIsLoading(true);
      const response = await getUserAccounts(changePage, changePageSize);
      // console.log('all users res:', response);
      if (response.total_items !== undefined) {
        setUsers(response.data);
        setTotalItems(response.total_items);
        setTotalPages(response.total_pages);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setIsLoading(false);
        setIsInitialLoader(false);
      } else if (response.error) {
        setUsers([]); // It is necessary to avoid the error  - users.map is not a function
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
      setUsers([]);
      setIsInitialLoader(false);
    } finally {
      setIsLoading(false);
    }
  };
  const handlerGetUserByIdentifier = async () => {
    try {
      setIsLoading(true);
      const response = await getUserAccounts(1, changePageSize, identifierUser);
      // console.log('identifier users res:', response);
      if (response.data !== undefined) {
        // setPaginateRender(true);
        setUsers(response.data);
        setTotalItems(response.total_items);
        setTotalPages(response.total_pages);
        setIsLoading(false);
      } else if (response.error) {
        setUsers([]); // It is necessary to avoid the error  - users.map is not a function
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const settingPage = (record) => {
    setIsLoading(true);
    // setPageSize(record);
    // setCurrentPage(1);
    setChangePage(1);
    setChangePageSize(record);
  };
  const resetInitialValues = () => {
    setIdentifierUser('');
    handlerGetUsers();
  };

  useEffect(() => {
    if (identifierUser) {
      handlerGetUserByIdentifier();
    } else {
      handlerGetUsers();
    }
  }, [changePage, changePageSize]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handlerGetUserByIdentifier();
    }
  };
  const searchHandler = () => {
    if (identifierUser) {
      handlerGetUserByIdentifier();
    } else {
      ToastErrorCath('Debes proporcionar el nombre o CRM del usuario antes de realizar la búsqueda.');
    }
  };
  return (
    <>
      {isLoading && <LoadingPulse />}
      {isInitialLoader ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <div className="page-title-container" ref={startRef}>
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
                <NavLink to="/" className="invisible">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Inicio</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>

          {/* *** Dropdown and Search Buttons Start *** */}
          <Row className="g-0 mb-3 mt-sm-5">
            {/* Search With ID Start */}
            <Col xs="auto" md="auto" className="mb-2 me-2">
              <div className="d-inline-block float-md-start pe-1 search-input-container  shadow bg-foreground me-3" style={{ width: '274px' }}>
                <Form.Control
                  type="text"
                  style={{ fontFamily: 'Montserrat' }}
                  placeholder="Buscar por crm id o razón social "
                  value={identifierUser}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setIdentifierUser(e.target.value);
                  }}
                />
                {!identifierUser ? (
                  <span className="search-magnifier-icon">
                    <CsLineIcons icon="search" />
                  </span>
                ) : (
                  <span className="search-delete-icon" onClick={() => resetInitialValues()}>
                    <CsLineIcons icon="close" />
                  </span>
                )}
              </div>
              <Button variant="primary" style={{ height: '36px', width: 'auto' }} onClick={searchHandler}>
                <span>Buscar</span>
              </Button>
            </Col>
            {/* Search With ID End */}
          </Row>
          {/* este div es para ocultar el contenido cuando esta cargando  , position: 'absolute', zIndex: '1' */}
          <div style={{ minHeight: '50vh' }} id="cardContainer">
            <Row className="g-0 d-flex justify-content-end aling-items-center">
              {/* Dropdown Start */}
              <Col xs="auto" className="p-0 ms-2">
                <Dropdown align={{ xs: 'end' }} className={`d-inline-block ${users?.length < 10 && 'invisible'}`}>
                  <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-18">
                    {`${pageSize} Usuarios`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-end">
                    <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                      10 Usuarios
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                      20 Usuarios
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                      50 Usuarios
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                      100 Usuarios
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              {/* Dropdown Start */}
            </Row>
            {/* *** Dropdown and Search Buttons End *** */}
            <Row className="g-0">
              <div className={`text-muted my-3 text-lg-end ${users.length < 2 && 'd-none'}`}>{`Página ${currentPage}`}</div>
            </Row>
            {/* *** Main Page Start *** */}
            {/* List Header End */}
            <Row className="g-0 mb-2 d-none d-lg-flex py-4 py-lg-0 ps-5 pe-4 custom-sort h-100">
              <Col lg="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                <div className="text-muted text-small cursor-pointer">CRM ID</div>
              </Col>
              <Col lg="4" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">RAZON SOCIAL</div>
              </Col>
              <Col lg="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                <div className="text-muted text-small cursor-pointer">ASESOR</div>
              </Col>
              <Col lg="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                <div className="text-muted text-small cursor-pointer">TIPO</div>
              </Col>
              <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">STATUS</div>
              </Col>
              <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">TARIFARIO</div>
              </Col>
              <Col lg="1" className=" pe-1 text-end">
                <div className="text-muted text-small">SALDO</div>
              </Col>
            </Row>
            {/* List Header End */}
            {/* User List Start */}
            {users?.length === 0 ? (
              <div className="d-flex flex-column align-items-center">
                {/* <h3 className="text-center">Usuario no identificado</h3> */}
                <img alt="sin datos" src="/img/animation/userNotfound.webp" style={{ width: '150px', height: '150px' }} />
              </div>
            ) : (
              users?.map((shipment, index) => <UserCard key={index} index={index} data={shipment} />)
            )}
            {/* {users && users?.map((shipment, index) => <UserCard key={index} index={index} data={shipment} />)} */}
            {/* User List End */}
            {/* Pagination Start */}
            <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
              <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
            </div>

            {/* Pagination End */}
          </div>
          {/* *** Main Page End *** */}
        </>
      )}
    </>
  );
};

export default Users;
