import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// api
import { getOverweightOrders, getOrderExcel } from '../../api/Api-admin';
// components
import OrderCard from './components/OrderCard';
import OrderModal from './components/OrderModal';
import AdminLoader from '../../../components/loading/AdminLoader';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../components/Paginate';

const OrderOverweight = () => {
  const title = 'Sobrepesos Ordenes';
  const description = 'Sobrepesos Ordenes Plataforma Autopaquete';
  // -Local States
  const [orders, setOrders] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // - Use references to do scroll Up
  const startRef = useRef(null);

  const handlerGetOrders = async (page, items) => {
    setIsLoading(true);

    try {
      const result = await getOverweightOrders(page, items);
      console.log('result', result);
      setOrders(result.orders);
      setIsLoading(false);
      // setOrders([]);
      setTotalPages(result.total_pages);
      startRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      // console.log('error en catch statement history:', error);
    }
  };

  useEffect(() => {
    handlerGetOrders(currentPage, pageSize);
  }, [pageSize, currentPage, render]);

  const settingPage = (limit) => {
    setIsLoading(true);
    setCurrentPage(1);
    setPageSize(limit);
  };

  //   const handleCreateOrder = async () => {
  //     const formData = new FormData();
  //     formData.append('excel_file', file[0]);
  //     setIsModalOpen(false);

  //     axios
  //       .post('https://api.autopaquete.com.mx/v1/bulk_shippings', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: token,
  //         },
  //       })
  //       .then((response) => {
  //         // console.log('CREATE ORDER RES:', response);
  //         if (response.data.order_id) {
  //           toast.success(`La orden ha sido generada exitosamente con número de Id: ${response.data.order_id}`, {
  //             position: 'top-right',
  //             autoClose: 5000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: 'colored',
  //           });
  //         }
  //         // REFRESH PAGE
  //         setTimeout(() => {
  //           handlerGetOrders(currentPage, pageSize);
  //         }, 2000);
  //       })
  //       .catch((err) => {
  //         console.error('Error:', err.response.data);
  //         toast.error(`Error: ${err.response.data.error} Código: ${err.response.data.error_code}`, {
  //           position: 'top-right',
  //           autoClose: 8000,
  //           hideProgressBar: true,
  //           closeOnClick: false,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'dark',
  //         });
  //       });
  //   };
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };
  const downloadZip = () => {
    getOrderExcel('MOWT').then((response) => {
      // console.log('ORDER OVERWEIGHT RES:', response);
      const blob = base64ToBlob(response.file, 'application/vnd.ms-excel');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Plantilla Sobrepesos.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
  // console.log('Ordenes:', orders);
  // console.log('archivo excel:', file);
  return (
    <>
      {isLoading && <LoadingPulse />}
      {isModalOpen && <OrderModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setRender={setRender} render={render} />}

      {orders === null ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <div className="page-title-container" ref={startRef}>
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto">
                <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back invisible" to="/">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Inicio</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
              {/* Excel Buttons Start */}
              <Col sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 me-2">
                <Button variant="primary" onClick={() => downloadZip()} style={{ height: '36px' }}>
                  <CsLineIcons size="15" icon="download" className="me-1 mb-1" />
                  <span>Plantilla Sobrepesos</span>
                </Button>
              </Col>
              {/* Excel  Buttons End */}
              {/* Top Buttons Start */}
              <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                <Button
                  className="me-2"
                  variant="outline-primary"
                  style={{ height: '36px' }}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <CsLineIcons icon="plus" size="15" className="mb-1" /> <span>Crear orden</span>
                </Button>
              </Col>
              {/* Top Buttons End */}
            </Row>
          </div>

          <Row className="mb-3">
            <Col md="5" lg="3" xxl="2" />
            <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
              <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1">
                <Dropdown.Toggle variant="foreground-alternate" className={`shadow sw-20 ${orders?.length === 0 && 'invisible'}`}>
                  {`${pageSize} Ordenes`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Ordenes
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Ordenes
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Ordenes
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Ordenes
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={`text-muted d-lg-none mt-3 ${orders?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div>
            </Col>
          </Row>

          <div style={{ minHeight: '350px' }} id="cardContainer">
            {/* List Header Start */}
            <Row className="g-0 mb-2 d-none d-lg-flex">
              <Row className="g-0 mb-2 d-none d-lg-flex">
                <Col xs="auto" className={`text-muted mb-3 ${orders?.length === 0 && 'invisible'}`}>
                  {`Página ${currentPage}`}
                </Col>
              </Row>
              <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-5 h-100">
                <Col xs="3" lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-small cursor-pointer">ID ORDEN</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                  <div className="text-muted text-small cursor-pointer">SOBREPESOS</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                  <div className="text-muted text-small cursor-pointer">CREADOS</div>
                </Col>
                <Col xs="2" lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                  <div className="text-muted text-small cursor-pointer">PENDIENTES</div>
                </Col>
                <Col xs="2" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center">
                  <div className="text-muted text-small cursor-pointer">COBRO TOTAL</div>
                </Col>
              </Row>
              {/* </Col> */}
            </Row>
            {/* List Header End */}
            {orders?.length === 0 ? (
              <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                <h3 className="text-center mt-5">Sin historial de órdenes.</h3>
              </div>
            ) : (
              <>
                {/* List Items Start  */}
                {orders?.map((order) => (
                  <OrderCard key={order.order_overweight_id} order={order} />
                ))}
                {/* List Items End */}
                {/* Pagination Start */}
                <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                  <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
                </div>
                {/* Pagination End */}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrderOverweight;
