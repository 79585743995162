import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import BillsCSV from '../../../../components/reactCsv/BillsCSV';
// api
import { getDocuments } from '../../../../api/Api-user';
// components
import LoadingPulse from '../../../../components/loading/LoadingPulse';
import RowBilling from './card/RowBilling';
import CardBilling from './card/CardBilling';
import Paginate from '../../../../components/Paginate';
import { ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';

const Invoices = ({ setIsLoadingStart }) => {
  const [invoices, setInvoices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // paginate
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [changePage, setChangePage] = useState(1); // para la peticion
  const [changeItemsNum, setChangeItemsNum] = useState(10); // para la peticion

  // - Use references to do scroll Up
  const startRef = useRef(null);
  const settingPage = (limit) => {
    setIsLoading(true);
    setChangeItemsNum(limit);
    setChangePage(1);
  };
  const handlerGetInvoices = async () => {
    try {
      const response = await getDocuments('invoices', changePage, changeItemsNum);
      // console.log('Get invoices response', response);
      if (response.data !== undefined) {
        setIsLoadingStart(false);
        setInvoices(response.data);
        setCurrentPage(response.current_page); // para actualizar la vista
        setPageSize(response.page_size); // para actualizar la vista
        setTotalPages(response.total_pages);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setTotalPages(0);
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handlerGetInvoices();
  }, [changePage, changeItemsNum]);

  // console.log(invoices)
  return (
    <>
      {isLoading && <LoadingPulse />}
      {invoices?.length === 0 ? (
        <div className="pt-5 mt-5">
          <h3 className="text-center mt-5 pt-5">
            No tienes facturas registradas.
            <img alt="box" src="/img/animation/doc.webp" style={{ width: '50px', height: '50px' }} />{' '}
          </h3>
        </div>
      ) : (
        <div ref={startRef} id="cardContainer">
          <Row className="g-0">
            {/* Dropdown Start */}
            <Col className="mb-3 text-end">
              {/* Excel Buttons  */}
              {invoices?.length > 0 && <BillsCSV invoices={invoices} />}
              {/* Dropdown  */}
              <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${invoices?.length < 10 && 'd-none'}`}>
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-18">
                  {`${pageSize} Facturas`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Facturas
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Facturas
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Facturas
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Facturas
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={`${invoices?.length < 2 && 'd-none'} mt-3 text-muted`}>{`Página ${currentPage}`}</div>
              {/* Length End */}
            </Col>
            {/* Dropdown Start */}
          </Row>

          {/* List Header Start */}
          <RowBilling />
          {/* List Header End */}
          {/* List Items Start */}
          {invoices?.map((invoice, index) => (
            <CardBilling data={invoice} doc="Factura" key={index} />
          ))}
          {/* List Items End */}
          {/* Pagination Start */}
          <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
            <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} setIsLoading={setIsLoading} />
          </div>
          {/* Pagination End */}
        </div>
      )}
    </>
  );
};

export default Invoices;
