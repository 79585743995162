import React from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import InputText from '../../../../components/form-elements/InputText2';
import SelectFile from '../../../../components/form-elements/SelectFile';

const PackageInfo = ({ values, errors, setValues, setErrors }) => {
  return (
    <Row className="g-3 mb-5">
      <h2 className="small-title">Información Paquete</h2>
      <div>
        Es crucial proporcionar la información correcta sobre el contenido, la cual debe coincidir con el comprobante entregado a la paquetería y la evidencia
        disponible.
      </div>

      <Col sm="9" className="position-relative">
        <InputText
          label="Contenido Paquete"
          icon="box"
          maxLength=""
          placeholder="Ej: 5 pantalones de mezclilla, $900 total"
          value={values.packageContent}
          isRequired="true"
          helpText="Producto, piezas y valor del producto ($)"
          fieldName="packageContent"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packageContent && <div className={`invalid-tooltip ${errors.packageContent ? 'd-block' : 'd-none'}`}>{errors.packageContent}</div>}
      </Col>
      <Col sm="9" className="position-relative">
        <InputText
          label="Tipo Embalaje"
          icon="box"
          maxLength=""
          placeholder="Ej: Caja cerrada con cinta canela y etiqueta con logo Autopaquete al costado"
          value={values.packagingDescription}
          isRequired="true"
          helpText="Descripción del material que se usó en el paquete, si tiene alguna calcomanía, logo o tipo de cinta rotulada."
          fieldName="packagingDescription"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packagingDescription && (
          <div className={`invalid-tooltip ${errors.packagingDescription ? 'd-block' : 'd-none'}`}>{errors.packagingDescription}</div>
        )}
      </Col>
      <Col sm="9" className="position-relative">
        <SelectFile
          label="Adjunta tu factura del contenido"
          placeholder="Sube o arrastra tu archivo"
          isRequired="true"
          fieldName="invoiceFile"
          formObj={setValues}
          setErrors={setErrors}
          helpText={`La descripción del contenido debe coincidir con la del comprobante y la fecha del comprobante debe ser anterior o igual al día en que creaste tu guía.
      *No se aceptan capturas de transferencias ni tickets de depósitos.`}
        />
        {errors.invoiceFile && <div className={`invalid-tooltip ${errors.invoiceFile ? 'd-block' : 'd-none'}`}>{errors.invoiceFile}</div>}
      </Col>
    </Row>
  );
};

export default PackageInfo;
