import React from 'react';
import { Form } from 'react-bootstrap';
import { Application, FileSelector } from 'react-rainbow-components';
// import updateFormField from '../../utils/updateFormField';

const SelectFile = ({ label, placeholder, helpText, isRequired, formObj, fieldName, setErrors }) => {
  const theme = {
    rainbow: {
      palette: {
        brand: '#850000',
      },
    },
  };

  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}
      <Application theme={theme}>
        <FileSelector
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto mx-auto my-0"
          placeholder={placeholder}
          bottomHelpText={helpText}
          onFocus={() => {
            setErrors((prevErrors) => {
              return { ...prevErrors, [fieldName]: '' };
            });
          }}
          onChange={(e) => {
            formObj((prevValue) => {
              return { ...prevValue, [fieldName]: e[0] };
            });
          }}
          borderRadius="semi-rounded"
        />
      </Application>
    </>
  );
};

export default SelectFile;
