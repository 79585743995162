import React, { useState } from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { updateFormField, deleteFormError } from '../../utils/updateFormField';

// api
import { getZipCode } from '../../api/Api-user';

const InputCP = ({ label, icon, maxLength, placeholder, value, isRequired, helpText, fieldName, validation, formObj, setErrors }) => {
  const [isLoading, setIsLoading] = useState(false);
  const getPostalCode = async (data) => {
    try {
      if (data.length === 5) {
        setIsLoading(true);
        const response = await getZipCode(data);
        console.log('getPostalCode response:', response);
        if (response.length > 0) {
          formObj((prevValues) => {
            return {
              ...prevValues,
              city: response[0].city,
              state: response[0].state.name,
            };
          });
          setErrors((prevErr) => {
            return { ...prevErr, city: '', state: '' };
          });
        } else {
          setErrors((prevErr) => {
            return { ...prevErr, [fieldName]: 'Código postal invalido' };
          });
        }
      } else {
        formObj((prevValues) => {
          return {
            ...prevValues,
            city: '',
            state: '',
          };
        });
      }
    } catch {
      console.log('error');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}
      <InputGroup>
        <InputGroup.Text>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{
                width: '10px',
                height: '10px',
                borderWidth: '1px',
                color: '#850000',
              }}
            />
          ) : (
            <CsLineIcons icon={icon} size="16" className="text-alternate" />
          )}
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            updateFormField(formObj, e.target.value, validation, fieldName);
            deleteFormError(setErrors, fieldName);
            getPostalCode(e.target.value);
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
    </>
  );
};

export default InputCP;
