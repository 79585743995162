import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// utils
import { initialsUpperCase, startUpperCase } from 'utils/textFormat';
// api
import AdditionalChargesRow from 'components/rows/AdditionalChargesRow';
import AdditionalChargesCard from 'components/cards/AdditionalChargesCard';
import OverweightRow from 'components/rows/OverweightRow';
import OverweightCard from 'components/cards/OverweightCard';
import PickupsRow from 'components/rows/PickupsRow';
import PickupsCard from 'components/cards/PickupsCard';
// api
import { getShippingDetails } from '../../../api/Api-user';
// components
import LoadingCar from '../../../components/loading/LoadingCar';
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});
const Detail = () => {
  const title = 'Detalle envío';
  const description = 'Detalle Envío Plataforma Autopaquete';
  const history = useHistory();
  // Local State
  const { labelId } = useParams();
  const [shippingDetailID, setShippingDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getShippingDetails(labelId)
      .then((response) => {
        console.log(response);
        if (response.shipping_code !== undefined) {
          setShippingDetail(response);
          setIsLoading(false);
        } else if (response.data?.error) {
          // console.log('GUIA NO EXISTENTE');
          history.goBack();
          const firstKey = Object.keys(response.data.error)[0];
          ToastErrorApi(response.data.error[firstKey]);
        }
      })
      .catch((err) => {
        console.log('error catch:', err);
        history.goBack();
        ToastErrorCath();
      });
  }, []);
  return (
    <>
      {isLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </div>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Top Buttons Start */}
            {shippingDetailID !== null && (
              <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                <a download={shippingDetailID.shipping_label_id} href={`data:application/pdf;base64,${shippingDetailID.label}`}>
                  <Button variant="outline-primary" className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                    <CsLineIcons icon="download" /> <span>Descargar Etiqueta</span>
                  </Button>
                </a>
              </Col>
            )}

            {/* Top Buttons End */}
          </Row>
        </div>
        {shippingDetailID !== null && (
          <Row>
            <Col xl="8" xxl="9">
              {/* Paqueteria Start */}
              <h2 className="small-title">Paquetería</h2>
              <Row className="g-2 mb-5">
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="plane" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">Servicio</div>
                          <div className="text-primary">{shippingDetailID.shipping_code.replace(/_+/g, ' ')}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="calendar" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">Fecha Creación</div>
                          <div className="text-primary">{moment(shippingDetailID.creation_date).format('DD-MM-YYYY HH:mm:ss')}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="clipboard" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">ID Envío</div>
                          <div className="text-primary">{shippingDetailID.shipping_label_id}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="shipping" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">Código Rastreo</div>
                          <div className="text-primary">{shippingDetailID.shipping_tracking_id}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* Paqueteria  End */}

              {/* Rastreo Start  inactive */}
              {/* <h2 className="small-title">Rastreo</h2>
                            <Card className="mb-5">
                                      <Card.Body>
                                        <Row className="g-0">
                                          <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                                            <div className="w-100 d-flex sh-1" />
                                            <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                                              <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                                            </div>
                                            <div className="w-100 d-flex h-100 justify-content-center position-relative">
                                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                                            </div>
                                          </Col>
                                          <Col className="mb-4">
                                            <div className="h-100">
                                              <div className="d-flex flex-column justify-content-start">
                                                <div className="d-flex flex-column">
                                                  <Button variant="link" className="p-0 pt-1 heading text-start">
                                                    Información del envío enviada a FedEx
                                                  </Button>
                                                  <div className="text-alternate">{ShippingDetail.update_date}</div>
                                                  <div className="text-muted mt-1">
                                                    La fecha de entrega puede ser actualizada una vez que FedEx reciba el paquete.
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                            </Card>  */}
              {/* Rastreo End inactive */}

              {/* Resumen Precio Start */}
              <h2 className="small-title">Resumen Precio</h2>
              <Card className="mb-5" style={{ minWidth: '300px' }}>
                <Card.Body className="ps-3 ps-sm-5">
                  <Row className="g-0 mb-3 d-flex flex-column flex-md-row">
                    <Col xs="auto" className="ps-2 pt-3 pb-0 pe-0 h-100">
                      <img src={shippingDetailID.service_img} className="card-img rounded-md h-100 sw-13 sw-sm-17 sw-md-18" alt="thumb" />
                    </Col>
                    <Col>
                      <div className="ps-2 ps-sm-4 pt-3 pb-0 pe-0 h-100 ">
                        <Row className="mb-3">
                          {/* Tarifa Base Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Tarifa Base</div>
                            <div className="text-muted text-small">{`${shippingDetailID.parcel.total_weight} KG`}</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.base)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shippingDetailID.shipping_rates.extra_weight === 0 && 'd-none'}`}>
                          {/* Tarifa Sobrepeso Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Sobrepeso</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.extra_weight)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shippingDetailID.shipping_rates.zonification === 0 && 'd-none'}`}>
                          {/* Tarifa Zonificacion Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Zonificación</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.zonification)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shippingDetailID.shipping_rates.extended_area === 0 && 'd-none'}`}>
                          {/* Tarifa Zona Extendida Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Zona extendida</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.extended_area)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shippingDetailID.shipping_rates.insurance === 0 && 'd-none'}`}>
                          {/* Tarifa Seguro Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Cargo por seguro</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.insurance)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shippingDetailID.shipping_rates.excess_weight === 0 && 'd-none'}`}>
                          {/* Tarifa exceso de peso Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Tarifa exceso de peso</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.excess_weight)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shippingDetailID.shipping_rates.excess_dimensions === 0 && 'd-none'}`}>
                          {/* Tarifa Exceso Dimensiones Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Tarifa exceso de dimenciones</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shippingDetailID.shipping_rates?.excess_dimensions)}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <hr />
                    <Row className="g-0 mb-2">
                      <Col xs="auto" className="ms-auto ps-3 text-muted">
                        Subtotal
                      </Col>
                      <Col xs="auto" className="sw-13 text-end">
                        <span>
                          <span className="text-small text-muted">$</span>
                          {fmDecimal.from(shippingDetailID.shipping_rates?.subtotal)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto" className="ms-auto ps-3 text-muted">
                        IVA
                      </Col>
                      <Col xs="auto" className="sw-13 text-end">
                        <span>
                          <span className="text-small text-muted">$</span>
                          {fmDecimal.from(shippingDetailID.shipping_rates?.iva)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto" className="ms-auto ps-3 fw-bold">
                        Total
                      </Col>
                      <Col xs="auto" className="sw-13 text-end">
                        <span className="text-primary fw-bold">
                          <span className="text-small text-muted">$</span>
                          {fmDecimal.from(shippingDetailID.shipping_rates?.total)}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
              {/* Resumen Precio End */}
              {/* Additional Charges Start */}
              <h2 className="small-title">Cargos Adicionales</h2>
              <Card className="mb-5">
                <Card.Body>
                  {shippingDetailID?.shipping_additional_charges.length === 0 ? (
                    <div className="py-0 mt-0 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                      <h4 className="text-center">Sin cargos adicionales</h4>
                    </div>
                  ) : (
                    <>
                      <AdditionalChargesRow />
                      {/* List Charges Items Start */}
                      {shippingDetailID?.shipping_additional_charges.map((charge, index) => (
                        <AdditionalChargesCard key={index} data={charge} />
                      ))}
                      {/* List Charges Items End */}
                    </>
                  )}
                </Card.Body>
              </Card>
              {/* Additional Charges End */}
              {/* Overweight Start */}
              <h2 className="small-title">Sobrepesos</h2>
              <Card className="mb-5">
                <Card.Body>
                  {shippingDetailID?.shipping_overweights.length === 0 ? (
                    <div className="d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                      <h4 className="text-center">Sin cargos por sobrepeso</h4>
                    </div>
                  ) : (
                    <>
                      <OverweightRow />
                      {/* List Charges Items Start */}
                      {shippingDetailID?.shipping_overweights.map((overweight, index) => (
                        <OverweightCard key={index} data={overweight} />
                      ))}
                      {/* List Charges Items End */}
                    </>
                  )}
                </Card.Body>
              </Card>
              {/* Overweight End */}
              {/* Pickups Start */}
              <h2 className="small-title">Recolecciones</h2>
              <Card className="mb-5">
                <Card.Body>
                  {shippingDetailID?.shipping_pickups.length === 0 ? (
                    <div className="d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                      <h4 className="text-center">Sin recolecciones agendadas</h4>
                    </div>
                  ) : (
                    <>
                      <PickupsRow />
                      {/* List Charges Items Start */}
                      {shippingDetailID?.shipping_pickups.map((pickup, index) => (
                        <PickupsCard key={index} data={pickup} />
                      ))}
                      {/* List Charges Items End */}
                    </>
                  )}
                </Card.Body>
              </Card>
              {/* Pickups End */}
            </Col>
            {/* Address Start */}
            <Col xl="4" xxl="3">
              {/* Address Start */}
              <h2 className="small-title">Envío</h2>
              <Card className="mb-5">
                <Card.Body>
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">DIRECCIÓN DE ORIGEN</p>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="user" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{initialsUpperCase(shippingDetailID.address_from?.name)}</Col>
                    </Row>
                    {shippingDetailID.address_from?.company && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="building" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shippingDetailID.address_from.company)}</Col>
                      </Row>
                    )}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="pin" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">
                        {initialsUpperCase(
                          `${shippingDetailID.address_from?.street} ${shippingDetailID.address_from?.street_number}, ${shippingDetailID.address_from?.neighborhood}, ${shippingDetailID.address_from?.zip_code}, ${shippingDetailID.address_from?.city}, ${shippingDetailID.address_from?.state}.`
                        )}
                      </Col>
                    </Row>
                    {shippingDetailID.address_from.reference && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="destination" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shippingDetailID.address_from.reference)}</Col>
                      </Row>
                    )}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="phone" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{shippingDetailID.address_from?.phone}</Col>
                    </Row>
                    {/* <Row className="g-0 mb-2">
                                        <Col xs="auto">
                                          <div className="sw-3 me-1">
                                            <CsLineIcons icon="email" size="17" className="text-primary" />
                                          </div>
                                        </Col>
                                        <Col className="text-alternate">{shippingDetailID.address_from.email}</Col>
                                      </Row> */}
                  </div>
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">DIRECCIÓN DE DESTINO</p>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="user" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{initialsUpperCase(shippingDetailID.address_to?.name)}</Col>
                    </Row>
                    {shippingDetailID.address_to?.company && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="building" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shippingDetailID.address_to?.company)}</Col>
                      </Row>
                    )}

                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="pin" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">
                        {initialsUpperCase(
                          `${shippingDetailID.address_to?.street} ${shippingDetailID.address_to?.street_number}, ${shippingDetailID.address_to?.neighborhood}, ${shippingDetailID.address_to?.zip_code}, ${shippingDetailID.address_to?.city}, ${shippingDetailID.address_to?.state}.`
                        )}
                      </Col>
                    </Row>
                    {shippingDetailID.address_to?.reference && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="destination" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shippingDetailID.address_to.reference)}</Col>
                      </Row>
                    )}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="phone" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{shippingDetailID.address_to.phone}</Col>
                    </Row>
                    {/* {shippingDetailID.parcel_to.email && (
                                        <Row className="g-0 mb-2">
                                          <Col xs="auto">
                                            <div className="sw-3 me-1">
                                              <CsLineIcons icon="email" size="17" className="text-primary" />
                                            </div>
                                          </Col>
                                          <Col className="text-alternate">{shippingDetailID.address_to.email}</Col>
                                        </Row>
                                      )} */}
                  </div>
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">PAQUETE</p>
                    {/* <Row className="g-0 mb-2">
                                                          <Col xs="auto">
                                                            <div className="sw-3 me-1">
                                                              <CsLineIcons icon="user" size="17" className="text-primary" />
                                                            </div>
                                                          </Col>
                                                          <Col className="text-alternate">{initialsUpperCase(shippingDetailID.name)}</Col>
                                                        </Row> */}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="edit-square" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">
                        {startUpperCase(shippingDetailID.parcel?.product_description)}
                        {shippingDetailID.parcel?.product_value > 0 && (
                          <OverlayTrigger delay={{ show: 500, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Valor del contenido</Tooltip>}>
                            <span>{` - $${shippingDetailID.parcel?.product_value}`}</span>
                          </OverlayTrigger>
                        )}
                      </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="box" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{`${shippingDetailID.parcel?.depth} x ${shippingDetailID.parcel.width} x ${shippingDetailID.parcel.height} - ${shippingDetailID.parcel.weight} kg`}</Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="abacus" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{`${shippingDetailID.parcel?.volumetric_weight} kg Volumétrico `}</Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="dollar" size="18" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{`${shippingDetailID.parcel?.total_weight} kg Total `}</Col>
                    </Row>
                    {shippingDetailID.parcel?.multi_pieces > 1 && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="print" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{`Multi-piezas ${shippingDetailID.parcel?.multi_pieces} `}</Col>
                      </Row>
                    )}
                  </div>
                </Card.Body>
              </Card>
              {/* Address End */}
            </Col>
            {/* Address End */}
          </Row>
        )}
      </div>
    </>
  );
};

export default Detail;
