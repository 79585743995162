import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Modal, Form } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Application, FileSelector } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from 'moment';

// api
import { getOrders, getFilteredOrders, getOrderExcel } from '../../../api/Api-user';
// components
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import CardOrder from './components/CardOrder';
import Paginate from '../../../components/Paginate';
import RainCalendar from '../../../components/calendars/RainCalendar';

const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};
const Orders = () => {
  const title = 'Ordenes';
  const description = 'Ordenes Plataforma Autopaquete';
  // Global states
  const { currentUser } = useSelector((state) => state.auth);

  // -Local States
  const [orders, setOrders] = useState(null);
  const [emptyHistory, setEmptyHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(null); // for hidden pagination
  const [currentPage, setCurrentPage] = useState(1); // for view pagination
  const [pageSize, setPageSize] = useState(10); // for view pagination
  const [changePage, setChangePage] = useState(1); // for the request
  const [changeItemsNum, setChangeItemsNum] = useState(10); // for the request
  const [isModalOpen, setIsModalOpen] = useState(false); // create order
  const [file, setFile] = useState(null); // create order
  // States for filtering
  const [filterBy, setFilterBy] = useState('orden');
  const [filteredMode, setFilteredMode] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectStartDate, setSelectStartDate] = useState({ date: new Date() });
  const [selectEndDate, setSelectEndDate] = useState({ date: new Date() });
  const [filteredSuccess, setFilteredSuccess] = useState('');
  const [disabledCalendar, setDisabledCalendar] = useState(true);
  const [disabledSearchInput, setDisabledSearchInput] = useState(false);

  // - Use references to do scroll Up
  const startRef = useRef(null);
  const token = `Bearer ${currentUser.api_key}`;

  const handlerGetOrders = async () => {
    if (orders) {
      // solo si orders es true [] activa este loader, si es falsy null usa el loader del car para los inicios
      setIsLoading(true);
    }
    try {
      const response = await getOrders(changePage, changeItemsNum);
      // console.log('handlerGetOrders response', response);
      if (response.shipping_orders !== undefined) {
        setOrders(response.shipping_orders);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        setTotalPages(response.total_pages);
        if (startRef.current && startRef.current.scrollIntoView) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setOrders(orders || []); // si orders es falsy actualiza con [] para eliminar el loader
        setEmptyHistory(orders || true);
        const firstKey = Object.keys(response.error)[0];
        const errorMessage = response.error.shipping_orders[0].trim();
        if (!errorMessage.includes('No se encontraron órdenes con los parámetros especificados.')) {
          // solo si es diferente a este error muestralo
          // es para que salga este mensaje cuando el historial esta vacio
          ToastErrorApi(response.error[firstKey]);
        }
      }
    } catch (error) {
      // console.log('error en catch statement history:', error);
      setOrders(orders || []); // si orders es falsy actualiza con [] para eliminar el loader
      ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };
  const handlerGetFilteredOrders = async () => {
    setEmptyHistory(false); // temporal para hacer pruebas
    if (filterBy !== 'periodo' && !orderId) {
      setFilteredMode(false); // si changePage es mayor a 1 lo pone en true el btn buscar pero aqui es error esto es para que la paginacion siga funcionando en modo no filtrando
      ToastErrorCath(`Por favor, ingresa la información necesaria para filtrar por ${filterBy}.`);
      return;
    }
    try {
      setIsLoading(true);
      const response = await getFilteredOrders(orderId, startDate, endDate, changePage, changeItemsNum);
      // console.log(' handlerGetFilteredOrders response', response);
      if (response.shipping_orders !== undefined) {
        setOrders(response.shipping_orders);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        setTotalPages(response.total_pages);
        setFilteredSuccess(
          `Filtro por ${filterBy} ${orderId} ${startDate ? ` del ${moment(startDate).format('DD-MM-YY')}` : ''} ${
            endDate ? `al ${moment(endDate).format('DD-MM-YY')}` : ''
          }`
        );
        if (startRef.current && startRef.current.scrollIntoView) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        // setFilteredMode(true); // aunque no fuera exitoso es necesario para que aparezca el btn regresar
        setOrders([]);
        setTotalPages(0);
        setFilteredSuccess(''); // para eliminar el mensaje "Filtro por servicio..."
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch (error) {
      // console.log('error en catch statement history:', error);
      ToastErrorCath();
    } finally {
      setIsLoading(false);
      setFilteredMode(true); // respuesta exitosa o no es necesario para mantener el modo filtrando y que aparezca el btn regresar
    }
  };
  const handleCreateOrder = async () => {
    const formData = new FormData();
    formData.append('excel_file', file[0]);
    setIsModalOpen(false);
    axios
      .post('https://api.autopaquete.com.mx/v1/bulk_shippings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      })
      .then((response) => {
        // console.log('CREATE ORDER RES:', response);
        if (response.data.order_id) {
          toast.success(`La orden ha sido generada exitosamente con número de Id: ${response.data.order_id}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
        // REFRESH PAGE
        setTimeout(() => {
          handlerGetOrders(currentPage, pageSize);
        }, 2000);
      })
      .catch((err) => {
        console.error('Error:', err.response.data);
        toast.error(`Error: ${err.response.data.error} Código: ${err.response.data.error_code}`, {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      });
  };
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };
  const downloadZip = () => {
    getOrderExcel('OTV1').then((response) => {
      // console.log('ORDER RES:', response);
      const blob = base64ToBlob(response.file, 'application/vnd.ms-excel');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Plantilla Ordenes.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
  const settingPage = (record) => {
    setIsLoading(true);
    // setCurrentPage(1); se eliminaron estos porque causaban re renderizados
    // setPageSize(limit);
    setChangeItemsNum(record); // ejecuta el useEffect que decide que funcion ejecutar
    setChangePage(1); // ejecuta el useEffect que decide que funcion ejecutar
  };
  const filterInitialValues = () => {
    setOrderId('');
    setStartDate('');
    setEndDate('');
    setFilteredSuccess(''); // para eliminar el mensaje "Filtro por nombre..."
    setSelectEndDate({ date: new Date() });
    setSelectStartDate({ date: new Date() });
  };
  const returnInitialMode = () => {
    // handlerGetFilteredHistory() o  handlerGetShippingHistory() activan el loader
    setFilteredMode(false);
    setFilterBy('orden'); // ejecuta el useEffect que prepara los estados para el filtrado
    filterInitialValues();
    if (changePage > 1) {
      setChangePage(1); // ejecuta el useEffect que decide que fn ejecutar pero con la pag 1
    } else {
      // si changePage es 1 no ejecuta el cambio de estado ni el useEffect es necesario llamar a la funcion
      handlerGetOrders();
    }
  };
  useEffect(() => {
    if (filterBy === 'orden') {
      // states to filter by order id
      setDisabledSearchInput(false);
      setEndDate('');
      setStartDate('');
      setDisabledCalendar(true);
      setDisabledSearchInput(false);
    } else if (filterBy === 'periodo') {
      // states to filter by period
      setDisabledSearchInput(true);
      setOrderId('');
      setDisabledCalendar(false);
      setDisabledSearchInput(true);
      setStartDate(moment(new Date()).format('YYYY-MM-DD'));
      setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    }
  }, [filterBy]);

  // - Whenever the pagination status change
  useEffect(() => {
    if (filteredMode) {
      handlerGetFilteredOrders();
    } else {
      handlerGetOrders();
    }
  }, [changePage, changeItemsNum]);
  // console.log('Ordenes:', orders);
  // console.log('modo filtro?:', filteredMode ? 'si' : 'no');
  // console.log('archivo excel:', file);
  return (
    <>
      {orders === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear orden de envíos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Application theme={theme}>
            <FileSelector
              className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto mx-auto"
              // style={containerStyles}
              label="Subir archivo excel"
              placeholder="Sube o arrastra tu archivo aquí"
              bottomHelpText="Selecciona solo un archivo"
              onChange={setFile}
              borderRadius="semi-rounded"
            />
          </Application>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setIsModalOpen(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCreateOrder}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Excel Buttons Start */}
            <Col sm="auto" className="mb-2 mb-sm-0 me-2 d-flex align-items-end justify-content-end order-3 order-sm-2">
              <Button variant="primary" onClick={() => downloadZip()} style={{ height: '36px' }}>
                <CsLineIcons size="15" icon="download" className="me-1 mb-1" />
                <span>Plantilla Ordenes</span>
              </Button>
            </Col>
            {/* Excel  Buttons End */}
            {/* Top Buttons Start */}
            <Col xs="auto" className="mb-2 mb-sm-0 d-flex align-items-end justify-content-end order-2 order-sm-3">
              <Button
                className="me-2"
                variant="outline-primary"
                style={{ height: '36px' }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <CsLineIcons icon="plus" size="15" className="mb-1" /> <span>Crear orden</span>
              </Button>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>
        <div style={{ minHeight: '350px' }} id="cardContainer">
          {/* Elements for Filtering Start */}
          <Row className="g-0 mt-sm-5 d-flex align-items-end bg-separatorw">
            {/* Filters Start */}
            <Col xs="auto" xl="9" className="bg-secondaryw">
              <Row className="g-0">
                <Col xs="auto" className="mb-2 me-2">
                  <Dropdown className="d-inline-block">
                    <Dropdown.Toggle variant="foreground-alternate" className="shadow  sw-21">
                      {`Filtrar por ${filterBy}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow sw-auto">
                      <Dropdown.Item onClick={() => setFilterBy('orden')}>Orden</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterBy('periodo')}>Periodo</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs="auto" lg="8" className="mb-2 bg-secondaryw" style={{ minWidth: '275px' }}>
                  <Form.Control
                    type="text"
                    style={{ fontFamily: 'Montserrat', width: '275px' }}
                    placeholder="Id de Orden"
                    value={orderId}
                    readOnly={disabledSearchInput}
                    onChange={(e) => {
                      setOrderId(e.target.value);
                    }}
                  />
                </Col>
                {/* Calendars Start */}
                <Col xs="12" sm="auto" className="mb-2 mb-xl-0">
                  <Row className="g-0 me-sm-2">
                    <Col xs="6" className="pe-2">
                      <RainCalendar
                        selectDate={selectStartDate}
                        setSelectDate={setSelectStartDate}
                        setState={setStartDate}
                        state={startDate}
                        // label="Fecha Inicial"
                        holder="Fecha Inicial"
                        width="100px"
                        isDisabled={disabledCalendar}
                      />
                    </Col>
                    <Col xs="6">
                      <RainCalendar
                        selectDate={selectEndDate}
                        setSelectDate={setSelectEndDate}
                        setState={setEndDate}
                        // label="Fecha Final"
                        holder="Fecha Final"
                        width="100px"
                        isDisabled={disabledCalendar}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* Calendars End */}
                <Col xs="12" md="auto">
                  <Row className="g-0">
                    <Col xs="auto" className="pe-2">
                      <Button
                        variant="primary"
                        style={{ height: '36px' }}
                        className="w-100"
                        onClick={() => {
                          if (changePage > 1) {
                            // la primera vez el estado filteredMode es falso
                            setFilteredMode(true); // para que el useEffect funcione bien
                            setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
                          } else {
                            handlerGetFilteredOrders();
                          }
                        }}
                      >
                        <span>Buscar</span>
                      </Button>
                    </Col>
                    {filteredMode && (
                      <Col xs="auto" className="p-0 bg-alternatew">
                        <Button variant="outline-primary" style={{ height: '36px' }} className="w-100" onClick={returnInitialMode}>
                          <span>Regresar</span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* Filters End */}
            {/* Items selector Start */}
            <Col xs="12" xl="3" className="mt-2 mt-lg-0 d-flex justify-content-end align-items-end bg-infow">
              <Row className="g-0">
                <Col>
                  <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${totalPages < 2 && 'd-none'}`}>
                    <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-18">
                      {`${pageSize} Ordenes`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow dropdown-menu-end">
                      <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                        10 Ordenes
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                        20 Ordenes
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                        50 Ordenes
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                        100 Ordenes
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
            {/* Items selector End */}
          </Row>
          <hr className="bg-muted mb-3 mt-4" />
          {/* Elements for Filtering Start */}

          {/* List Header Start */}
          <div className={`text-muted mb-3 text-end ${orders?.length < 2 && 'd-none'}`}>{`Página ${currentPage}`}</div>
          {filteredSuccess && <div className="text-muted mb-3">{filteredSuccess}</div>}
          <Row className={`g-0 mb-2 d-none d-lg-flex ${orders?.length === 0 && 'invisible'}`}>
            <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-5 h-100">
              <Col xs="3" lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                <div className="text-muted text-small cursor-pointer">ID ORDEN</div>
              </Col>
              <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
              </Col>
              <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">ENVÍOS</div>
              </Col>
              <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">CREADOS</div>
              </Col>
              <Col xs="2" lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">PENDIENTES</div>
              </Col>
              <Col
                xs="2"
                lg="1"
                className="d-flex flex-column pe-1 justify-content mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center"
              >
                <div className="text-muted text-small cursor-pointer">ERRORES</div>
              </Col>
            </Row>
            {/* </Col> */}
          </Row>
          {/* List Header End */}
          {orders?.length === 0 ? (
            <div className="py-5 mt-lg-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              {emptyHistory && <h3 className="text-center mt-5">Aún no tienes historial de Ordenes</h3>}
              <img alt="empty" src="/img/animation/userNotFound.webp" style={{ width: '150px', height: '150px' }} />
            </div>
          ) : (
            <>
              {/* List Items Start */}
              {orders?.map((order) => (
                <CardOrder key={order.id} order={order} />
              ))}
              {/* List Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;
