import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
// components
import ButtonRequest from '../../../../components/buttons/ButtonRequest';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';
// api
import { returnShipping } from '../../../api/Api-admin';

const ReturnShippingModal = ({ data = {}, isModalOpen = '', setIsModalOpen = '', setUpdatePage = '' }) => {
  // console.log('guia data:', data);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsAddChargerLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handlerReturnShipping = async () => {
    try {
      handleButtonAction();
      const response = await returnShipping(data.shipping_label_id);
      console.log('return guia response:', response);
      if (response.shipping_check) {
        ToastSuccess('La devolución de la guía se ha realizado exitosamente ⭐');
        setUpdatePage(true);
      } else if (response.error) {
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsModalOpen(false);
    }
  };
  return (
    <>
      {/* Confirm Modal Start */}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5" />
        </Modal.Header>
        <Modal.Body>
          <h5>¿Estás segur@ de que deseas devolver el costo de la guía?</h5>
          <h5>{`Total: $ ${data.shipping_rates.total}`}</h5>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end ">
            <ButtonRequest title="Si, Devolver" handlerFn={handlerReturnShipping} isButtonDisabled={isButtonDisabled} isLoader={isAddChargerLoader} />

            <Button variant="outline-primary" onClick={() => setIsModalOpen(false)} style={{ height: '36px' }}>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Confirm Modal End */}
    </>
  );
};
export default ReturnShippingModal;
