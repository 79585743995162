import { cleanData, cleanShippingId } from './textFormat';

const updateFormField = (setObj, data, validation, field) => {
  // Remove spaces and special characters
  let cleanedData = cleanData(data);
  // if field is "shipping_id" use this cleanShippingId
  if (field === 'shippingId') {
    cleanedData = cleanShippingId(data);
  }
  if (cleanedData === '' || validation.test(cleanedData)) {
    setObj((prevValues) => {
      return { ...prevValues, [field]: cleanedData };
    });
  }
};

const deleteFormError = (setObj, field) => {
  setObj((prevValue) => {
    return { ...prevValue, [field]: '' };
  });
};
export { updateFormField, deleteFormError };
