import React from 'react';
// import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// external libraries
import moment from 'moment';

const CardIncident = ({ ticket }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col className="py-4 py-lg-0 ps-3 ps-sm-5 pe-2 pe-3 pe-sm-5 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="11" sm="9" lg="4" className="mb-2 my-sm-4 my-lg-0 pe-3 d-flex flex-column justify-content-center h-lg-100 order-1 bg-infow">
              <div className="text-muted text-small d-lg-none mb-1">Reporte / ID Envío</div>
              <div className="p-0 pe-2 text-primary d-flex align-items-center">{ticket.subject.toUpperCase()}</div>
            </Col>
            <Col
              xs="7"
              sm="3"
              lg="2"
              className="pe-1 my-4 my-sm-0 d-flex flex-column justify-content-center align-items-sm-end align-items-lg-start order-3 bg-successw"
            >
              <div className="text-muted text-small d-lg-none">Fecha / Hora</div>
              <div className="lh-1 text-alternate">{`${moment.utc(ticket.createdTime).local().format('DD-MM-YY HH:mm')}`}</div>
            </Col>
            <Col
              xs="5"
              // sm="2"
              lg="1"
              className="pt-2 pt-sm-0 pe-1 mb-sm-2 mb-lg-0 d-flex flex-column justify-content-center align-items-end align-items-sm-start align-items-lg-end  align-items-lg-center order-4 bg-infow"
            >
              <div className="text-muted text-small d-lg-none">ID Reporte</div>
              <div className="text-medium text-primary">{`${ticket.ticketNumber}`}</div>
            </Col>
            <Col
              xs="7"
              //  sm="3"
              lg="2"
              className="pt-2 pt-sm-0 pe-1 mb-sm-2 mb-lg-0 d-flex flex-column justify-content-center align-items-start align-items-lg-end order-4 bg-secondaryw"
            >
              <div className="text-muted text-small d-lg-none">Actualización</div>
              <div className="text-alternate">{`${moment.utc(ticket.customerResponseTime).local().format('DD-MM-YY HH:mm')}`}</div>
            </Col>
            <Col
              xs="1"
              sm="6"
              lg="1"
              className="pt-2 mb-2 mb-lg-0 d-flex flex-column justify-content-center align-items-end order-2 order-sm-last order-lg-4 bg-alternatel"
            >
              <div className="text-muted text-small d-lg-none">Agente</div>

              {ticket?.assignee?.firstName === 'Valeria' && (
                <OverlayTrigger
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">{`${ticket.assignee?.firstName} ${ticket.assignee?.lastName}`}</Tooltip>}
                >
                  <img alt="agent" width="38px" className="rounded-circle" src="https://api.autopaquete.com.mx/media/valeria_montalvo.webp" />
                </OverlayTrigger>
              )}
              {ticket.assignee?.firstName === 'Natalie' && (
                <OverlayTrigger
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">{`${ticket.assignee?.firstName} ${ticket.assignee?.lastName}`}</Tooltip>}
                >
                  <img alt="agent" width="38px" className="rounded-circle" src="https://api.autopaquete.com.mx/media/natalie_loyola.webp" />
                </OverlayTrigger>
              )}
            </Col>
            <Col
              xs="5"
              sm="6"
              lg="2"
              className="mb-2 mb-lg-0 px-lg-2 d-flex flex-column justify-content-center align-items-end align-items-sm-start align-items-lg-end text-lg-end order-4 order-lg-last bg-secondaryw"
            >
              <div className="text-muted text-small d-lg-none">Estatus</div>
              <div className="text-alternate">{ticket.status}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default CardIncident;
