import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// components
import LoadingCar from 'components/loading/LoadingCar';
import ExpediteDelivery from './components/forms/ExpediteDelivery';
import DamagedPack from './components/forms/DamagedPack';
// import IncompletePack from './components/forms/IncompletePack';
import ChangeAddress from './components/forms/ChangeAddress';
import ErrDelivery from './components/forms/ErrDelivery';
import LostPack from './components/forms/LostPack';
import ReturnPack from './components/forms/ReturnPack';
// import Overweight from './components/forms/Overweight';

import LoadingPulse from '../../components/loading/LoadingPulse';

const NewReport = () => {
  const title = 'Crear Reporte';
  const description = 'Reporte Incidentes Plataforma Autopaquete';
  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState(true);

  const title1 = 'Agilizar Entrega';
  const title2 = 'Cambio Domicilio';
  const title3 = 'Entrega Erronea';
  const title4 = 'Paquete Perdido o Percance Externo';
  const title5 = 'Paquete Dañado o Incompleto';
  const title6 = 'Retornar Paquete';
  const [incidentType, setIncidentType] = useState(title1);
  const [form, setForm] = useState(<ExpediteDelivery />);

  const formController = (incident) => {
    switch (incident) {
      case title1:
        setForm(<ExpediteDelivery />);
        break;
      case title2:
        setForm(<ChangeAddress />);
        break;
      case title3:
        setForm(<ErrDelivery />);
        break;
      case title4:
        setForm(<LostPack />);
        break;
      case title5:
        setForm(<DamagedPack />);
        break;
      case title6:
        setForm(<ReturnPack />);
        break;
      default:
        // enter here at the beginning before it reads the state value
        // ExpediteDelivery I put this to always show something
        <ExpediteDelivery />;
    }
  };
  const setPage = (incident) => {
    setIncidentType(incident);
  };
  useEffect(() => {
    setIsLoading(true);
    formController(incidentType);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [incidentType]);
  // console.log('loading?:', isLoading);
  useEffect(() => {
    setTimeout(() => {
      setIsInitLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isInitLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/reportes/historial">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1"> Atras </span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </div>
        <Row className="mb-3">
          <Col sm="12" lg="10" className="mb-1 text-end">
            <Dropdown
              align={{
                xs: 'end',
              }}
              className="d-inline-block ms-1"
            >
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-auto">
                {incidentType}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item onClick={() => setPage(title1)} className={`${incidentType === title1 ? 'd-none' : 'd-flex'}`}>
                  {title1}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setPage(title2)} className={`${incidentType === title2 ? 'd-none' : 'd-flex'}`}>
                  {title2}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setPage(title3)} className={`${incidentType === title3 ? 'd-none' : 'd-flex'}`}>
                  {title3}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setPage(title4)} className={`${incidentType === title4 ? 'd-none' : 'd-flex'}`}>
                  {title4}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setPage(title5)} className={`${incidentType === title5 ? 'd-none' : 'd-flex'}`}>
                  {title5}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setPage(title6)} className={`${incidentType === title6 ? 'd-none' : 'd-flex'}`}>
                  {title6}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
          </Col>
        </Row>
        {isLoading ? <LoadingPulse /> : form}
      </div>
    </>
  );
};

export default NewReport;
