import React from 'react';
import { useDispatch } from 'react-redux';

import { Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
// reducer
import { setParcelSelected } from '../../../../slices/parcels/parcelSelectedSlice';

// External libraries
const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 0,
});

const CardParcel = ({ parcel, setIsModalOpen }) => {
  const dispatch = useDispatch();

  const openModalParcel = () => {
    dispatch(setParcelSelected(parcel));
    setIsModalOpen(true);
    // console.log('*****:', parcel);
  };
  return (
    <Card className="mb-2">
      <Card.Body className="py-3 py-lg-0 sh-auto sh-sm-auto sh-lg-8">
        <Row className="g-0 h-100 align-content-center">
          <Col xs="11" lg="3" className="mb-2 pe-lg-4 mb-lg-0 d-flex flex-column justify-content-center order-1 order-lg-1 h-lg-100 position-relative bg-infox">
            <div className="text-muted text-small d-lg-none">Nombre</div>
            <OverlayTrigger delay={{ show: 500, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Editar paquete</Tooltip>}>
              <button
                type="button"
                className="btn-open-addresses-modal text-start text-primary text-nowrap"
                onClick={() => openModalParcel()}
                style={{
                  overflowX: 'scroll',
                  maxWidth: '100%',
                }}
              >
                {parcel.name.toUpperCase()}
              </button>
            </OverlayTrigger>
          </Col>
          <Col xs="12" sm="12" lg="4" className="mb-2 mb-lg-0 pe-lg-4 d-flex flex-column justify-content-center order-3 order-lg-2">
            <div className="text-muted text-small d-lg-none">Descripcion</div>
            <div
              className="text-alternate text-nowrap"
              style={{
                overflowX: 'scroll',
                maxWidth: '100%',
              }}
            >
              {parcel.product_description.toUpperCase()}
            </div>
          </Col>
          <Col
            xs="6"
            lg="1"
            className="d-flex flex-column justify-content-center align-items-end align-items-sm-center align-items-lg-start mb-2 mb-lg-0 order-5 order-lg-3"
          >
            <div className="text-muted text-small d-lg-none">Largo</div>
            <div className="text-alternate">
              {parcel.depth} <span className="text-small text-muted">cm</span>
            </div>
          </Col>
          <Col xs="6" lg="1" className="d-flex flex-column justify-content-center  mb-2 mb-lg-0 order-4 order-lg-4">
            <div className="text-muted text-small d-lg-none">Ancho</div>
            <div className="text-alternate">
              {parcel.width} <span className="text-small text-muted">cm</span>
            </div>
          </Col>
          <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-4">
            <div className="text-muted text-small d-lg-none">Alto</div>
            <div className="text-alternate">
              {parcel.height} <span className="text-small text-muted">cm</span>
            </div>
          </Col>
          <Col
            xs="6"
            lg="1"
            className="d-flex flex-column justify-content-center align-items-end align-items-sm-center align-items-lg-start mb-2 mb-lg-0 order-last order-lg-5"
          >
            <div className="text-muted text-small d-lg-none mb-1">Kg</div>
            <div className="text-alternate">{parcel.weight}</div>
          </Col>
          <Col xs="12" lg="1" className="d-flex flex-column justify-content-center align-items-end mb-2 mb-lg-0 order-last order-lg-5 ">
            <div className="text-muted text-small d-lg-none mb-1">Valor del contenido</div>
            <span className="text-alternate">
              <span className="text-small text-muted">$ </span>
              {parcel.product_value === '' ? 0 : fmDecimal.from(parseInt(parcel.product_value, 10))}
            </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default CardParcel;
