import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import moment from 'moment';

const PickupsCard = ({ data }) => {
  return (
    <Card className="mb-2 px-0">
      <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
        <Col className="py-4 py-lg-0 px-4 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
          <Row className="g-0 h-100 align-content-center">
            <Col
              xs="6"
              lg="2"
              className="mb-4 mb-lg-0 pe-lg-3 h-lg-100 d-flex flex-column justify-content-center align-items-end align-items-lg-start  order-2 order-lg-1 bg-infoW"
            >
              <div className="text-muted text-small d-lg-none">Id Recolección</div>
              <div className="lh-1 text-primary">{data?.pickup_id}</div>
            </Col>
            <Col xs="6" lg="2" className="pe-1 mb-4 mb-lg-0 d-flex flex-column justify-content-center order-4 order-lg-2 bg-infow">
              <div className="text-muted text-small d-lg-none">Fecha</div>
              <div className="lh-1 text-alternate ">{moment(data.pickup_date).format('DD-MM-YYYY')}</div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="mb-4 mb-lg-0 ps-lg-4 d-flex flex-column justify-content-lg-center align-items-end align-items-lg-center order-5 order-lg-3 bg-successw"
            >
              <div className="text-muted text-small d-lg-none">Paquetes</div>
              <div className="lh-1 text-alternate">{data.quantity}</div>
            </Col>
            <Col xs="6" lg="1" className="d-flex flex-column mb-4 mb-lg-0 pe-1 justify-content-center align-items-lg-center order-1 order-lg-4 bg-secondaryw">
              <div className="text-muted text-small d-lg-none">Peso Total</div>
              <div className="text-primary">{data?.total_weight}</div>
            </Col>
            <Col xs="6" lg="3" className="mb-1 mb-lg-0 d-flex flex-column justify-content-center align-items-end order-last order-lg-5 bg-infow">
              <div className="text-muted text-small d-lg-none">Ciudad</div>
              <div className="text-primary">{data?.city}</div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="pe-1 mb-1 mb-lg-0 d-flex flex-column justify-content-center align-items-start align-items-lg-end order-5 order-lg-last bg-secondaryw"
            >
              <div className="text-muted text-small d-lg-none">CP</div>
              <div className="text-primary">{data?.zip_code}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default PickupsCard;
