import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
// components
import ButtonRequest from '../../../../components/buttons/ButtonRequest';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';
import { editUserAccount } from '../../../api/Api-admin';

const EditUserModal = ({ isModalOpen, setIsModalOpen, data, setUpdatePage }) => {
  // console.log('---->', data);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsAddChargerLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handlerEditUserAccount = async () => {
    try {
      console.log('datos para editar:', data);
      handleButtonAction();
      const response = await editUserAccount(data);
      if (response.message !== undefined) {
        setUpdatePage(true);
        ToastSuccess(`El usuario ${data.crmId} ha sido editado correctamente`);
      } else if (response.error) {
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsModalOpen(false);
    }
  };
  return (
    <Modal className="modal-right" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Editar Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-5">{`Por favor, confirma si deseas editar la información del usuario ${data.crmId} con los datos siguientes:`}</div>
        <div className="text-alternate mb-2">
          Cuenta:
          <span className="ps-2 text-primary">{data.isAccountActive ? 'ACTIVA' : 'INACTIVA'}</span>
        </div>
        <div className="text-alternate mb-2">
          Saldo Automático:
          <span className="ps-2 text-primary">{data.isAutoBalance ? 'SI' : 'NO'}</span>
        </div>
        <div className="text-alternate mb-2">
          Comprobante Automático:
          <span className="ps-2 text-primary">{data.isAutoVouchers ? 'SI' : 'NO'}</span>
        </div>
        <div className="text-alternate mb-2">
          Multipiezas:
          <span className="ps-2 text-primary">{data.isMultiPieces ? 'SI' : 'NO'}</span>
        </div>
        <div className="text-alternate mb-2">
          Cliente Tipo:
          <span className="ps-2 text-primary">{data.customerType}</span>
        </div>
        <div className="text-alternate mb-2">
          Vendedor:
          <span className="ps-2 text-primary">{data.adviser}</span>
        </div>
        <div className="text-alternate mb-2">
          Tarifario:
          <span className="ps-2 text-primary">{data.rate}</span>
        </div>
        <div className="text-alternate mb-2">
          Etiqueta:
          <span className="ps-2 text-primary">{data.labelType}</span>
        </div>
        <div className="text-alternate mb-2">
          Regimen:
          <span className="ps-2 text-primary">{data.taxSystem}</span>
        </div>
        <hr />
        <div className="d-flex justify-content-center mt-5">
          <ButtonRequest title="Editar" handlerFn={handlerEditUserAccount} isButtonDisabled={isButtonDisabled} isLoader={isAddChargerLoader} />

          {/* <Button variant="primary" disabled={isButtonDisabled} className="me-2" onClick={handlerEditUserAccount}>
            {isAddChargerLoader && (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{
                  width: '13px',
                  height: '13px',
                  borderWidth: '1px',
                  color: '#FFFF',
                }}
              />
            )}
            <span>Editar</span>
          </Button> */}
          <Button variant="primary" onClick={() => setIsModalOpen(false)} style={{ height: '36px' }}>
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserModal;
