import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const ButtonRequest = ({ title, handlerFn, isButtonDisabled, isLoader }) => {
  return (
    <Button
      variant="primary"
      className="me-2 w-auto ms-2 mb-2 d-flex justify-content-between align-items-center"
      onClick={() => handlerFn()}
      style={{ height: '36px' }}
    >
      {isLoader && (
        <Spinner
          as="span"
          animation="border"
          role="status"
          disabled={isButtonDisabled}
          aria-hidden="true"
          style={{
            width: '13px',
            height: '13px',
            borderWidth: '1px',
            color: '#FFFF',
          }}
        />
      )}
      {title}
    </Button>
  );
};
export default ButtonRequest;
