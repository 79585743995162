import React from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const InputTextFetchData = ({
  label = '',
  icon = '',
  maxLength = '',
  placeholder = '',
  isRequired = '',
  helpText = '',
  isLoading = '',
  isError = '',
  error = '',
  value = '',
  setValues = '',
  setField = '',
  cleanError = '',
  validation = '',
  fieldName = '',
}) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}

      <InputGroup>
        <InputGroup.Text>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{
                width: '10px',
                height: '10px',
                borderWidth: '1px',
                color: '#850000',
              }}
            />
          ) : (
            <CsLineIcons icon={icon} size="16" className="text-alternate" />
          )}
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValues(e);
            setField(e.target.value, validation, fieldName);
            // Validar que cleanError exista antes de llamarlo
            if (cleanError) {
              cleanError('', fieldName);
            }
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
      <span className={`text-medium ${isError ? 'visible' : 'invisible'}`}>{error}</span>
    </>
  );
};

export default InputTextFetchData;
