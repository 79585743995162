// libraries
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastSuccess = (msg) => {
  toast.success(msg, {
    position: 'top-right',
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};
const ToastErrorApi = (array, errCode = '') => {
  let msgErr = ' ';
  array.forEach((error) => {
    msgErr = `Error: ${error}`;
  });
  toast.error(msgErr, {
    position: 'top-right',
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};
const ToastErrorCath = (msg = 'Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo. (catch error)') => {
  toast.error(msg, {
    position: 'top-right',
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export { ToastSuccess, ToastErrorApi, ToastErrorCath };

// const firstKey = Object.keys(response.error)[0];
// ToastErrorApi(response.error[firstKey]);

// dropdown  [].length < 10 && 'd-none' (en los que tienen excel debe ser d-none)
// numero de pagina [].length < 2 && 'd-none'
// Row [].length === 0 && 'invisible' (en overweight and pickups no)
// paginate totalPages < 2 && 'd-none'}
