import React from 'react';
import { Form } from 'react-bootstrap';
import { updateFormField } from '../../utils/updateFormField';

const InputTextArea = ({ value, label, placeholder, isRequired, fieldName, formObj, validation }) => {
  return (
    <div>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}
      <Form.Floating className="mb-3">
        <Form.Control
          id="floatingInputCustom"
          type="text"
          placeholder={placeholder}
          value={value}
          style={{ height: '100px' }}
          onChange={(e) => {
            updateFormField(formObj, e.target.value, validation, fieldName);
          }}
        />
        <label htmlFor="floatingInputCustom">Escribe un comentario</label>
      </Form.Floating>
    </div>
  );
};

export default InputTextArea;
