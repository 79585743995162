import React from 'react';
import { Pagination } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// users pagination
const Paginate = ({ currentPage, totalPages, setCurrentPage, setIsLoading }) => {
  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';

  const fetchPageNumbers = () => {
    const setRange = (from, to, step = 1) => {
      /**
       * Helper method for creating a range of numbers
       * range(1, 5) => [1, 2, 3, 4, 5]
       */
      let i = from;
      const range = [];
      while (i <= to) {
        range.push(i);
        i += step;
      }
      return range;
    };
    const PAGE_NEIGHBOURS = 1;
    const totalNumbers = PAGE_NEIGHBOURS * 2 + 3; // 7
    const totalBlocks = totalNumbers + 2; // 9

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - PAGE_NEIGHBOURS); // 4
      const endPage = Math.min(totalPages - 1, currentPage + PAGE_NEIGHBOURS); // 8
      let pages = setRange(startPage, endPage); // (4,8) [4,5,6,7,8]
      const hasLeftSpill = startPage > 2; // 4 mayor a 2 = TRUE
      const hasRightSpill = totalPages - endPage > 1; // 215 - 8 = 207 mayor a 1 = TRUE
      const spillOffset = totalNumbers - (pages.length + 1); // 7-5 = 2

      switch (true) {
        // handle: (1) < {211 212} [213] {214 215} (215)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = setRange(startPage - spillOffset, startPage - 1); // 4-2 y 4-1 (2,3)[2,3]
          pages = [LEFT_PAGE, ...extraPages, ...pages]; // [ < 2, 3, 4 ,5 ,6, 7,8 ]
          break;
        }
        // handle: (1) {2 3} [4] {5 6} > (215)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = setRange(endPage + 1, endPage + spillOffset); // 8+1 y 8+2 (9,10 )[9,10]
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (215)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]; // [< 4,5,6,7,8,9,10 >]
          break;
        }
      }
      return [1, ...pages, totalPages]; // (1) < {4 5} [6] {7 8} > (215)
    }

    return setRange(1, totalPages); // if there are less than 9 pages
  };
  const pages = fetchPageNumbers();
  // console.log('paginacion num pag:', pages);
  return (
    <>
      <Pagination>
        <div className="d-flex">
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <Pagination.Prev
                  className="shadow active"
                  id="firstPage"
                  key={index}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    setIsLoading(true);
                  }}
                >
                  <CsLineIcons icon="chevron-left" />
                </Pagination.Prev>
              );
            if (page === RIGHT_PAGE)
              return (
                <Pagination.Next
                  className="shadow active"
                  id="next"
                  key={index}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    setIsLoading(true);
                  }}
                >
                  <CsLineIcons icon="chevron-right" />
                </Pagination.Next>
              );
            return (
              <Pagination.Item
                className={`shadow ${currentPage === page ? 'active' : ''}`}
                key={index}
                id={page}
                onClick={() => {
                  if (currentPage !== page) {
                    setCurrentPage(page);
                    setIsLoading(true);
                  }
                }}
              >
                {page}
              </Pagination.Item>
            );
          })}
        </div>
      </Pagination>
    </>
  );
};

export default Paginate;
