import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const InputText = ({ label, icon, maxLength, placeholder, value, isRequired, helpText, fieldName, cleanError, setField, validation }) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}
      <InputGroup>
        <InputGroup.Text>
          <CsLineIcons icon={icon} size="16" className="text-alternate" />
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setField(e.target.value, validation, fieldName);
            cleanError('', fieldName);
            // console.log(`Fiels value onchange in:${fieldName} es:{${e.target.value}}`);
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
    </>
  );
};

export default InputText;
