import React from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import InputTextArea from '../../../../components/form-elements/InputTextArea';

const Footer = ({ values, setValues, responseTime, warning = 'fedaultvalues' }) => {
  return (
    <Row>
      <Col className="mb-5">
        <InputTextArea
          value={values.comment}
          label="¿Tienes algún comentario adicional?"
          formObj={setValues}
          fieldName="comment"
          validation={/^[A-Z0-9 ]+$/i}
        />
        <h2 className="pre-wrap mt-5 small-title text-primary text-center">{responseTime}</h2>
        <div className="pre-wrap mx-auto my-5 text-center text-alternate bg-infox" style={{ maxWidth: '800px' }}>
          {warning}
        </div>
        <div className="pre-wrap text-medium mx-auto text-center text-alternate ">
          IMPORTANTE: TODO REPORTE DE INCIDENCIA DE GUÍAS GENERADAS A TRAVÉS DE NUESTRA PLATAFORMA, DEBE SER INFORMADO A NUESTRA ÁREA DE SOPORTE LOGÍSTICO, EL
          SEGUIMIENTO SE LE NOTIFICARÁ MEDIANTE NUESTRO PERSONAL.
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
