import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// components
import CardOverweight from './components/CardOverweight';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import OverweightCSV from '../../../components/reactCsv/OverweightCSV';
import Paginate from '../../../components/Paginate';
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';

// Api
import { getOverweightHistory } from '../../../api/Api-user';

const Overweight = () => {
  const title = 'Sobrepesos';
  const description = 'Sobrepesos Plataforma Autopaquete';
  //  const { currentUser } = useSelector((state) => state.auth);

  // -Local States
  const [overweights, setOverweights] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [changePage, setChangePage] = useState(1); // para la peticion
  const [changeItemsNum, setChangeItemsNum] = useState(10); // para la peticion
  const [isLoading, setIsLoading] = useState(false);
  // - Use references to do scroll Up
  const startRef = useRef(null);
  const handlerGetOverweight = async () => {
    try {
      if (overweights) {
        // solo si overweights es true [] activa este loader, si es falsy null usa el loader del car para los inicios
        setIsLoading(true);
      }
      const response = await getOverweightHistory(changePage, changeItemsNum);
      // console.log('response overweights:', response);
      if (response.overweights !== undefined) {
        setOverweights(response.overweights);
        setCurrentPage(response.current_page); // para actualizar la vista
        setPageSize(response.page_size); // para actualizar la vista
        setTotalPages(response.total_pages);
        if (startRef.current && startRef.current.scrollIntoView) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setOverweights([]);
        setTotalPages(0);
        const firstKey = Object.keys(response.error)[0];
        const errorMessage = response.error.overweights[0].trim();
        if (!errorMessage.includes('No se encontraron envíos con los parámetros especificados.')) {
          // solo si es diferente a este error muestralo
          // es para que salga este mensaje cuando el historial esta vacio
          ToastErrorApi(response.error[firstKey]);
        }
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };

  const settingPage = (limit) => {
    // setIsLoading(true);
    setChangePage(1);
    setChangeItemsNum(limit);
  };
  useEffect(() => {
    handlerGetOverweight();
  }, [changePage, changeItemsNum]);
  // console.log('overweight:', overweights);
  return (
    <>
      {overweights === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Excel Buttons Start  crmId={currentUser.crmId} */}
            <Col sm="auto" className="d-flex align-items-end justify-content-end" />
            {/* Excel  Buttons End */}
          </Row>
        </div>
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row>
            <Col className="mb-1 text-end">
              {/* Excel Buttons  */}
              {overweights?.length > 9 && <OverweightCSV overweights={overweights} crmId="" />}
              {/* Dropdown  */}
              <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${overweights?.length < 10 && 'invisible'}`}>
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                  {`${pageSize} Sobrepesos`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Sobrepesos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Sobrepesos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Sobrepesos
                  </Dropdown.Item>
                  {/* <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                  100 Sobrepesos
                </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <div className={`${overweights?.length === 0 && 'invisible'} text-muted my-3 d-lg-flex`}>{`Página ${currentPage}`}</div>
            </Col>
          </Row>
          {/* List Header Start */}
          <Row className="g-0 mb-2 d-none d-lg-flex">
            {/* <div className={`text-muted mb-3 ${overweights?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div> */}
            <Col xs="auto" className="sw-11 d-none d-lg-flex" />
            <Col>
              <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
                <Col xs="3" lg="4" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">FECHA CARGO</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
                  <div className="text-muted text-small cursor-pointer">KG COBRADOS</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
                  <div className="text-muted text-small cursor-pointer">KG REALES</div>
                </Col>
                <Col
                  xs="2"
                  lg="1"
                  className="d-flex flex-column pe-1 justify-content mb-2 mb-lg-0 align-items-center order-2 order-lg-last justify-content-lg-center"
                >
                  <div className="text-muted text-small cursor-pointer">CARGO</div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* List Header End */}
          <div style={{ minHeight: '60vh' }}>
            {overweights?.length === 0 ? (
              <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                <h3 className="text-center my-3">No tienes sobrepesos.</h3>
                <img alt="overweight" src="/img/animation/overweight.webp" style={{ width: '80px', height: '80px' }} />
              </div>
            ) : (
              <>
                {/* List overweights Items Start */}
                {overweights?.map((overweight, index) => (
                  <CardOverweight key={index} overweight={overweight} />
                ))}
                {/* List overweights Items End */}
                {/* Pagination Start */}
                <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                  <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} setIsLoading={setIsLoading} />
                </div>
                {/* Pagination End */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Overweight;
