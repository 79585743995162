import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, Card } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// components
import EditUserModal from './EditUserModal';
import { ToastErrorCath } from '../../../../components/alerts/toast';
// API
import { getRateList } from '../../../api/Api-admin';

const EditUser = ({ user, setUpdatePage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ratesList, setRatesList] = useState([]); // tarifario list

  const [dataToUpdate, setDataToUpdate] = useState({
    crmId: user.crm_user_id,
    isAccountActive: user.is_active,
    isAutoBalance: user.is_update_balance,
    isAutoVouchers: false, // no tengo de donde obtenerlo
    isMultiPieces: user.is_multipiece_available,
    customerType: user.customer_type,
    adviser: user.seller,
    rate: user.rates_list === null ? '-- x --' : user.rates_list, // tarifario
    labelType: user.label_type === null ? '-- x --' : user.label_type, // etiqueta
    taxSystem: user.tax_system, // regimen
    zip: '', // no tengo de donde obtenerlo
  });

  useEffect(() => {
    getRateList()
      .then((response) => {
        // console.log('get rates:', response);
        if (response.rates_list) {
          setRatesList(response.rates_list);
        } else {
          ToastErrorCath();
        }
      })
      .catch(() => ToastErrorCath());
  }, []);
  // console.log('editar usuario:', dataToUpdate);

  return (
    <>
      {/* Edit user Modal Start */}
      {isModalOpen && <EditUserModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={dataToUpdate} setUpdatePage={setUpdatePage} />}
      {/*  Edit user Modal End */}
      <Card className="mb-5 mt-0 mt-lg-0 pt-lg-2">
        <Card.Body className="h-100">
          <Row className="g-0 bg-infok">
            <Col xs="12" md="6" xl="12" className="bg-successx d-flex flex-column justify-content-start">
              <div className="d-flex align-items-center mb-2">
                {user.is_active ? (
                  <CsLineIcons icon="activity" size="19" className="text-muted" />
                ) : (
                  <CsLineIcons icon="close-circle" size="19" className="text-muted" />
                )}
                <div className="text-alternate mx-2">CUENTA {dataToUpdate.isAccountActive ? 'ACTIVA' : 'INACTIVA'} </div>
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  checked={dataToUpdate.isAccountActive}
                  onClick={() =>
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, isAccountActive: !dataToUpdate.isAccountActive };
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <CsLineIcons icon="dollar" size="19" className="text-muted" />
                <div className="text-alternate mx-2">SALDO AUTOMATICO</div>
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer ms-2  mb-1"
                  checked={dataToUpdate.isAutoBalance}
                  onClick={() =>
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, isAutoBalance: !dataToUpdate.isAutoBalance };
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2 ">
                <CsLineIcons icon="invoice" size="19" className="text-muted" />
                <div className="text-alternate mx-2">COMPROBANTES FISCALES</div>
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer ms-2  mb-1"
                  checked={dataToUpdate.isAutoVouchers}
                  onClick={() =>
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, isAutoVouchers: !dataToUpdate.isAutoVouchers };
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <CsLineIcons icon="duplicate" size="19" className="text-muted" />
                <div className="text-alternate mx-2">MULTIPIEZAS</div>
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer ms-2 mb-1"
                  checked={dataToUpdate.isMultiPieces}
                  onClick={() =>
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, isMultiPieces: !dataToUpdate.isMultiPieces };
                    })
                  }
                />
              </div>
            </Col>
            <Col xs="12" md="6" xl="12" className="bg-warnings d-flex flex-column justify-content-start">
              <div className="d-flex align-items-center mb-2">
                <CsLineIcons icon="shield" size="19" className="text-muted" />
                <div className="text-alternate mx-2"> CLIENTE TIPO </div>
                <Dropdown>
                  <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                    {dataToUpdate.customerType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-start">
                    <Dropdown.Item
                      className={`${dataToUpdate.customerType === 'T' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, customerType: e.target.innerText };
                        })
                      }
                    >
                      T
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.customerType === 'C' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, customerType: e.target.innerText };
                        })
                      }
                    >
                      C
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.customerType === 'D' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, customerType: e.target.innerText };
                        })
                      }
                    >
                      D
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center mb-2">
                <CsLineIcons icon="support" size="19" className="text-muted" />
                <div className="text-alternate mx-2"> VENDEDOR </div>
                <Dropdown>
                  <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                    {dataToUpdate.adviser}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-start">
                    <Dropdown.Item
                      className={`${dataToUpdate.adviser === 'V000' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, adviser: e.target.innerText };
                        })
                      }
                    >
                      V000
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.adviser === 'V002' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, adviser: e.target.innerText };
                        })
                      }
                    >
                      V002
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.adviser === 'V003' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, adviser: e.target.innerText };
                        })
                      }
                    >
                      V003
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.adviser === 'V004' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, adviser: e.target.innerText };
                        })
                      }
                    >
                      {' '}
                      V004
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.adviser === 'V005' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, adviser: e.target.innerText };
                        })
                      }
                    >
                      V005
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center mb-2">
                {user.rates_list !== null ? (
                  <CsLineIcons icon="star" size="19" className="text-muted" />
                ) : (
                  <CsLineIcons icon="close-circle" size="19" className="text-muted" />
                )}
                <div className="text-alternate mx-2"> TARIFARIO </div>
                <Dropdown>
                  <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                    {dataToUpdate.rate}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-start" style={{ height: '200px', overflowY: 'scroll' }}>
                    {ratesList?.map((rates) => (
                      <Dropdown.Item
                        key={rates.id}
                        onClick={() =>
                          setDataToUpdate((prevValues) => {
                            return { ...prevValues, rate: rates.name };
                          })
                        }
                      >
                        {rates.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center mb-2">
                {dataToUpdate.labelType !== null ? (
                  <CsLineIcons icon="print" size="19" className="text-muted" />
                ) : (
                  <CsLineIcons icon="close-circle" size="19" className="text-muted" />
                )}
                <div className="text-alternate mx-2"> ETIQUETA </div>
                <Dropdown>
                  <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                    {dataToUpdate.labelType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-start">
                    <Dropdown.Item
                      className={`${dataToUpdate.labelType === 'COMPACT' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, labelType: e.target.innerText };
                        })
                      }
                    >
                      COMPACT
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${dataToUpdate.labelType === 'STANDARD' && 'd-none'}`}
                      onClick={(e) =>
                        setDataToUpdate((prevValues) => {
                          return { ...prevValues, labelType: e.target.innerText };
                        })
                      }
                    >
                      STANDARD
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center mb-2">
                <CsLineIcons icon="suitcase" size="19" className="text-muted" />
                <div className="text-alternate mx-2">REGIMEN</div>
                <input
                  className="text-primary"
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                  value={dataToUpdate.taxSystem}
                  onChange={(e) => {
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, taxSystem: e.target.value };
                    });
                  }}
                />
              </div>
            </Col>
            <Col xs="12" className="bg-mutedx d-flex justify-content-end align-items-end mt-3">
              <Button
                variant="primary"
                className="btn-icon btn-icon-start w-auto mt-3 mt-lg-1"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <CsLineIcons icon="edit" /> <span> Editar </span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditUser;
