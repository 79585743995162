import React from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import AutoFill from '../../../../components/form-elements/AutoFill';
import InputText from '../../../../components/form-elements/InputText2';
import InputCP from '../../../../components/form-elements/InputCP';

const AddressInfo = ({ values, errors, setValues, setErrors, info, type = 'Destino' }) => {
  // console.log('values en address component :', values);
  // fieldName props must be the same name of values obj
  return (
    <Row className="g-3 my-5">
      <h2 className="small-title">{`Dirección ${type}`}</h2>
      <div>{info}</div>
      <Col sm="9" className="position-relative">
        <InputText
          label="Calle"
          icon="home"
          maxLength=""
          placeholder=""
          value={values.street}
          isRequired="true"
          fieldName="street"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.street && <div className={`invalid-tooltip ${errors.street ? 'd-block' : 'd-none'}`}>{errors.street}</div>}
      </Col>
      <Col sm="3" className="position-relative">
        <InputText
          label="Número"
          icon="pin"
          maxLength=""
          placeholder=""
          value={values.streetNumber}
          isRequired="true"
          fieldName="streetNumber"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.streetNumber && <div className={`invalid-tooltip ${errors.streetNumber ? 'd-block' : 'd-none'}`}>{errors.streetNumber}</div>}
      </Col>
      <Col sm="9" className="position-relative">
        <InputText
          label="Colonia"
          icon="home"
          maxLength=""
          placeholder=""
          value={values.neighborhood}
          isRequired="true"
          fieldName="neighborhood"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.neighborhood && <div className={`invalid-tooltip ${errors.neighborhood ? 'd-block' : 'd-none'}`}>{errors.neighborhood}</div>}
      </Col>
      <Col sm="3" />
      <Col sm="4" className="position-relative">
        <InputCP
          label="Código Postal"
          icon="pin"
          maxLength="5"
          placeholder=""
          value={values.cp}
          isRequired="true"
          fieldName="cp"
          validation={/^[0-9]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.cp && <div className={`invalid-tooltip ${errors.cp ? 'd-block' : 'd-none'}`}>{errors.cp}</div>}
      </Col>
      <Col xs="12" sm="8" className="ps-sm-5 d-flex overflow-x-hidden">
        <Col xs="auto" className="me-5">
          <AutoFill label="Ciudad" value={values.city} readOnly />
        </Col>
        <Col xs="auto" className="me-5">
          <AutoFill label="Estado" value={values.state} readOnly />
        </Col>
      </Col>
      <Col md="9" className="position-relative">
        <InputText
          label="Entre Calles"
          icon="minimize"
          maxLength=""
          placeholder="Ej: Entre Hidalgo y Morelos"
          value={values.betweenStreet}
          isRequired="true"
          fieldName="betweenStreet"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.betweenStreet && <div className={`invalid-tooltip ${errors.betweenStreet ? 'd-block' : 'd-none'}`}>{errors.betweenStreet}</div>}
      </Col>
      <Col sm="9" className="position-relative">
        <InputText
          label="Descripcion Fachada"
          icon="home-garage"
          maxLength=""
          placeholder="Ej: Casa dos pisos, color blanco con ventanal, reja negra"
          value={values.facade}
          isRequired="true"
          helpText="Agregar si es casa, departamentos, oficinas, local o empresa."
          fieldName="facade"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.facade && <div className={`invalid-tooltip ${errors.facade ? 'd-block' : 'd-none'}`}>{errors.facade}</div>}
      </Col>
      <Col sm="9" className="position-relative">
        <InputText
          label="Referencias del lugar"
          icon="destination"
          maxLength=""
          placeholder='Ej: A un lado de lavanderia "Burbujas"'
          value={values.references}
          isRequired="true"
          fieldName="references"
          validation={/^[A-Z0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.references && <div className={`invalid-tooltip ${errors.references ? 'd-block' : 'd-none'}`}>{errors.references}</div>}
      </Col>
      <Col sm="9" className="position-relative">
        <InputText
          label="Teléfono destinatario"
          icon="phone"
          maxLength="10"
          placeholder="Nuevo número de contacto de destino"
          value={values.phoneTo}
          isRequired="true"
          helpText="La paquetería realizará una llamada para confirmar la información del reporte con el destinatario. Por favor, asegúrate de que pueda recibir llamadas en el número proporcionado."
          fieldName="phoneTo"
          validation={/^[0-9 ]+$/i}
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.phoneTo && <div className={`invalid-tooltip ${errors.phoneTo ? 'd-block' : 'd-none'}`}>{errors.phoneTo}</div>}
      </Col>
    </Row>
  );
};

export default AddressInfo;
