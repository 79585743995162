const initialsUpperCase = (string) => {
  const initialRegex = /(^\w{1})|(\s+\w{1})/g;
  if (string) {
    string = string.toLowerCase();
    return string.replace(initialRegex, (initial) => initial.toUpperCase());
  }
  return '';
};

const startUpperCase = (string) => {
  if (string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};
const cutToTwoWords = (str) => {
  const words = str.split(' ');
  return words.slice(0, 2).join(' ');
};
const toUpperIfString = (value) => {
  return typeof value === 'string' ? value.toUpperCase() : value;
};
const cleanData = (str) => {
  let cleanedStr;
  if (typeof str === 'string') {
    cleanedStr = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Elimina acentos
      .replace(/[^a-zA-Z0-9 ]/g, ''); // Elimina caracteres especiales, excepto letras, números y espacios
    cleanedStr = cleanedStr.trimStart(); // Eliminar espacios en blanco al inicio y al final
    // Reemplazar múltiples espacios al final por un solo espacio
    cleanedStr = cleanedStr.replace(/\s+$/, ' ');
  }
  return cleanedStr;
};
const cleanShippingId = (str) => {
  // It must be called in the function that updates the field
  // and in the function that makes the request for the shipping details.
  let cleanedStr;
  if (typeof str === 'string') {
    cleanedStr = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Elimina acentos
      .replace(/[^a-zA-Z0-9 ]/g, '') // Elimina caracteres especiales, excepto letras, números y espacios
      .replace(/\s+/g, '');
  }
  return cleanedStr;
};
export { initialsUpperCase, startUpperCase, cutToTwoWords, toUpperIfString, cleanData, cleanShippingId };
