import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Modal, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// components
import LoadingCar from '../../../components/loading/LoadingCar';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import ModalParcel from './components/ModalParcel';
import CardParcel from './components/CardParcel';
import Paginate from '../../../components/Paginate';
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';

// api
import { getParcels, getFilteredParcels } from '../../../api/Api-user';

const Parcels = () => {
  const title = 'Paquetes';
  const description = 'Paquetes Plataforma Autopaquete';
  // - Local States
  const [parcelList, setParcelList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyHistory, setEmptyHistory] = useState(null);
  // - Start Filter States
  const [parcelName, setParcelName] = useState('');
  // - End Filter States
  // - Start States for Components for Filtering
  const [filteredMode, setFilteredMode] = useState(false);
  const [filteredSuccess, setFilteredSuccess] = useState('');
  // - End States for Components for Filtering
  // - Start Pagination States
  const [currentPage, setCurrentPage] = useState(1); // for the view
  const [pageSize, setPageSize] = useState(10); // for the view
  const [changePage, setChangePage] = useState(1); // for the request
  const [changePageSize, setChangePageSize] = useState(10); // for the request
  const [totalPages, setTotalPages] = useState(0);
  // - End Pagination States
  // - Use references to do scroll Up
  const startRef = useRef(null);

  const handlerGetParcels = async () => {
    try {
      if (parcelList) {
        // solo si parcelList es true [] activa este loader, si es falsy null usa el loader del car para los inicios
        setIsLoading(true);
      }
      const response = await getParcels(changePage, changePageSize);
      if (response.parcel !== undefined) {
        // console.log('response', response);
        setParcelList(response.parcel);
        setTotalPages(response.total_pages);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
        setParcelList(parcelList || []); // si addressesList es falsy actualiza con [] para eliminar el loader
        setTotalPages(0);
      }
    } catch {
      ToastErrorCath();
      setParcelList(parcelList || []); // si parcelsList es falsy actualiza con [] para eliminar el loader
    } finally {
      setIsLoading(false);
    }
  };
  const handlerGetFilteredParcels = async () => {
    if (!parcelName) {
      setFilteredMode(false); // si changePage es mayor a 1 lo pone en true el btn buscar pero aqui es error esto es para que la paginacion siga funcionando en modo no filtrando
      ToastErrorCath('Por favor, ingresa un nombre para filtrar las direcciones.');
      return;
    }
    try {
      setIsLoading(true);
      const response = await getFilteredParcels(parcelName, changePage, changePageSize);
      // console.log('handlerGetFilteredParcels', response);
      if (response.parcel) {
        setParcelList(response.parcel);
        setTotalPages(response.total_pages);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        setFilteredSuccess(`Paquetes con el nombre: ${parcelName}`);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsLoading(false);
      setFilteredMode(true); // respuesta exitosa o no es necesario para mantener el modo filtrando y que aparezca el btn regresar
    }
  };
  const returnInitialMode = () => {
    // handlerGetFilteredHistory() o  handlerGetShippingHistory() activan el loader
    setFilteredMode(false);
    setParcelName('');
    setFilteredSuccess('');
    if (changePage > 1) {
      setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
    } else {
      // si changePage es 1 no ejecuta el cambio de estado ni el useEffect es necesario llamar a la funcion
      handlerGetParcels();
    }
  };
  useEffect(() => {
    if (filteredMode) {
      handlerGetFilteredParcels();
    } else {
      handlerGetParcels();
    }
  }, [isModalOpen, changePage, changePageSize]);
  // console.log(parcelList);
  const settingPage = (limit) => {
    setIsLoading(true);
    // setCurrentPage(1);
    // setPageSize(limit);
    setChangePage(1);
    setChangePageSize(limit);
  };

  return (
    <>
      {parcelList === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      {/* Address Modal Start */}
      <Modal className="modal-right" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Editar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalParcel setIsModalOpen={setIsModalOpen} />
        </Modal.Body>
      </Modal>
      {/* Address Modal End */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0 mb-3 ">
            {/* Title Start col-auto */}
            <Col xs="auto" className="col-auto mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* button Add Address Start */}
            <Col xs="auto" className="d-flex align-items-end justify-content-end mt-3 mb-sm-0 order-sm-3">
              <Button
                href="/ajustes/paquetes/agregar"
                variant="outline-primary"
                className="btn-icon w-100 btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1  w-md-auto"
              >
                <CsLineIcons icon="plus" /> <span> Agregar </span>
              </Button>
            </Col>
            {/* button Add Address End */}
          </Row>
        </div>
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row className="g-0 mb-3 mt-3">
            {/* Search Star */}
            <Col xs="12" sm="7">
              <Row className="g-0">
                <Col xs="12" lg="auto" className="mt-2 me-2">
                  {/* Search Start */}
                  <div className="d-inline-block float-md-start me-1 mb-1 search-input-container shadow bg-foreground">
                    <Form.Control
                      type="text"
                      placeholder="Buscar por nombre"
                      value={parcelName}
                      style={{ width: '275px' }}
                      onChange={(e) => {
                        setParcelName(e.target.value);
                      }}
                    />
                  </div>
                  {/* Search End */}
                </Col>
                <Col xs="auto" className="pe-2 mt-2">
                  <Button
                    variant="primary"
                    style={{ height: '36px' }}
                    className="w-100"
                    onClick={() => {
                      if (changePage > 1) {
                        // la primera vez el estado filteredMode es falso
                        setFilteredMode(true); // para que el useEffect funcione bien
                        setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
                      } else {
                        handlerGetFilteredParcels();
                      }
                    }}
                  >
                    <span>Buscar</span>
                  </Button>
                </Col>
                {filteredMode && (
                  <Col xs="auto" className="mt-2">
                    <Button variant="outline-primary" style={{ height: '36px' }} className="w-100" onClick={returnInitialMode}>
                      <span>Regresar</span>
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
            {/* Search End */}
            {/* Items selector Start */}
            <Col xs="12" sm="5" lg="5" className={`mt-2 mt-sm-0 d-flex justify-content-end align-items-end ${parcelList?.length < 10 && 'd-none'} bg-infow`}>
              <Dropdown align={{ xs: 'end' }} className="mb-2 d-inline-block ms-1">
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-18">
                  {`${pageSize} Paquetes`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Paquetes
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Paquetes
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Paquetes
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Paquetes
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Items selector End */}
          </Row>
          <hr className="bg-muted mb-3 mt-4" />

          {filteredSuccess && <div className="text-muted mb-3">{filteredSuccess}</div>}
          <div className={`text-muted mb-3 text-end ${parcelList?.length < 2 && 'd-none'}`}>{`Página ${currentPage}`}</div>
          {/* List Header Start */}

          <Row
            className={`g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort align-items-center ${
              parcelList?.length === 0 && 'invisible'
            }`}
          >
            <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
              <div className="text-muted text-small cursor-pointer">NOMBRE</div>
            </Col>
            <Col lg="4" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">DESCRIPCION</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">LARGO</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">ANCHO</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">ALTO</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">KG</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-lg-end">
              <div className="text-muted text-small cursor-pointer text-end">VALOR CONTENIDO</div>
            </Col>
          </Row>
          {/* List Header End */}
          {parcelList?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              {emptyHistory && <h3 className="text-center mt-5">Tu historial de paquetes está vacío.</h3>}
              <img alt="empty" src="/img/animation/userNotFound.webp" style={{ width: '150px', height: '150px' }} />
            </div>
          ) : (
            <>
              {/* List Addresses Items Start */}
              {parcelList?.map((parcel) => (
                <CardParcel key={parcel.id} parcel={parcel} setIsModalOpen={setIsModalOpen} />
              ))}
              {/* List Addresses Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Parcels;
