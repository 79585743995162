import React, { useEffect, useState } from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import { Modal } from 'react-rainbow-components';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// api
import { getUser, getComments, addComment, editComment, deleteComment } from '../../api/Api-admin';
// components
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
import EditUser from './components/EditUser';
import AdminLoader from '../../../components/loading/AdminLoader';

import LoadingRed from '../../../components/loading/LoadingRed';
// import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../components/Paginate';
import BalanceModal from './components/BalanceModal';
import OverweightModal from './components/OverweightModal';
import LastInvoices from './components/finance/invoices/LastInvoices';
import LastReceipts from './components/finance/receipts/LastReceipts';
import LastReps from './components/finance/reps/LastReps';
import LastStatements from './components/movements/statement/LastStatements';
import LastOverweights from './components/movements/overweight/LastOverweights';
import LastShippings from './components/movements/shippings/LastShippings';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const UserDetail = () => {
  const title = 'Detalle Usuario';
  const description = 'Detalle Usuario Plataforma Autopaquete';
  const history = useHistory();
  const { crmUserId } = useParams();
  // Local State
  const [user, setUser] = useState(null);
  const [isModalIncreaseOpen, setIsModalIncreaseOpen] = useState(false);
  const [isModalReduceOpen, setIsModalReduceOpen] = useState(false);
  const [isModalOwOpen, setIsModalOwOpen] = useState(false);
  const [billingHistory, setBillingHistory] = useState(null);
  const [movementHistory, setMovementHistory] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [idToEdit, setIdToEdit] = useState('');
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState({ value: false, id: '' });
  // paginate states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const handlerGetUser = async () => {
    try {
      const response = await getUser(crmUserId);
      if (response.data !== undefined) {
        setUser(response.data[0]);
        // console.log('response user detail:', response.data);
        // handlerGetComments(response.data[0].crm_user_id);
        setBillingHistory(<LastInvoices crmId={response.data[0].crm_user_id} />);
        setMovementHistory(<LastStatements crmId={response.data[0].crm_user_id} userName={response.data[0].legal_name} updatePage={updatePage} />);
        setUpdatePage(false);
      } else if (response.error) {
        history.push('/users-all');
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      history.push('/users-all');
      // ToastErrorCath();
    }
  };
  const handlerGetComments = async () => {
    try {
      setIsLoadingComments(true);
      const response = await getComments(crmUserId, currentPage, 5);
      // console.log('get comments res:', response);
      if (response.comments !== undefined) {
        setComments(response.comments);
        setTotalItems(response.total_items);
        setTotalPages(response.total_pages);
        setIsLoadingComments(false);
      } else if (response.error) {
        history.push('/users-all');
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsLoadingComments(false);
    }
  };

  const billingRenderer = (e) => {
    const type = e.target.name;
    const crmId = user.crm_user_id;
    const submenuBtn = document.querySelectorAll('ul#billing-nav li button');
    const currentBtn = e.currentTarget.name;
    submenuBtn.forEach((btn) => {
      if (currentBtn === btn.name) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
    switch (type) {
      case 'invoice':
        setBillingHistory(<LastInvoices crmId={crmId} />);
        break;
      case 'reps':
        setBillingHistory(<LastReps crmId={crmId} />);
        break;
      case 'receipts':
        setBillingHistory(<LastReceipts crmId={crmId} />);
        break;
      default:
        setBillingHistory(<LastInvoices crmId={crmId} />);
    }
  };

  const movementRenderer = (e) => {
    const type = e.target.name;
    const crmId = user.crm_user_id;
    const userName = user.legal_name;
    const submenuBtn = document.querySelectorAll('ul#statement-nav li button');
    const currentBtn = e.currentTarget.name;
    submenuBtn.forEach((btn) => {
      if (currentBtn === btn.name) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });

    switch (type) {
      case 'statements':
        setMovementHistory(<LastStatements crmId={crmId} userName={userName} />);
        break;
      case 'overweights':
        setMovementHistory(<LastOverweights crmId={crmId} userName={userName} />);
        break;
      case 'shippings':
        setMovementHistory(<LastShippings crmId={crmId} userName={userName} />);
        break;
      default:
        setMovementHistory(<LastShippings crmId={crmId} />);
    }
  };
  const handlerAddComment = async () => {
    try {
      const response = await addComment(user.crm_user_id, newComment);
      // console.log(newComment);
      if (response.created) {
        setNewComment('');
        handlerGetComments();
        ToastSuccess('El comentario ha sido guardado exitosamente');
      } else if (response.error) {
        history.push('/users-all');
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    }
  };

  const handlerDeleteComment = async (id) => {
    try {
      const response = await deleteComment(id);
      // console.log('res delete comment:', response); Bounce, Slide, Zoom
      if (response.message) {
        setIsModalDeleteOpen({ value: false, id: '' });
        setNewComment('');
        handlerGetComments();
        ToastSuccess('El comentario ha sido eliminado exitosamente');
      } else if (response.error) {
        history.push('/users-all');
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    }
  };
  const handlerEditComment = async () => {
    try {
      // setIsEditing(true);
      const response = await editComment(user.crm_user_id, idToEdit, newComment);
      // console.log('coment edit res:', response);
      if (response.created) {
        setNewComment('');
        handlerGetComments();
        ToastSuccess('El comentario ha sido editado exitosamente');
      } else if (response.error) {
        history.push('/users-all');
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsEditing(false);
    }
  };
  const setValuesToEdit = (edited) => {
    setNewComment(edited.value);
    setIdToEdit(edited.id);
    setIsEditing(true);
  };
  // Get User Data and Update when a charge has been applied
  useEffect(() => {
    // console.log('Actualizar pagina!!!');
    handlerGetUser();
  }, [updatePage]);
  useEffect(() => {
    handlerGetComments();
  }, [currentPage]);
  return (
    <>
      {user === null ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />

          {isModalReduceOpen && (
            <BalanceModal
              isModalOpen={isModalReduceOpen}
              setIsModalOpen={setIsModalReduceOpen}
              title="Cargos Adicionales"
              type="reduce"
              crmId={user?.crm_user_id}
              setUpdatePage={setUpdatePage}
            />
          )}
          {isModalIncreaseOpen && (
            <BalanceModal
              isModalOpen={isModalIncreaseOpen}
              setIsModalOpen={setIsModalIncreaseOpen}
              title="Agregar Saldo"
              type="increase"
              crmId={user?.crm_user_id}
              setUpdatePage={setUpdatePage}
            />
          )}
          <OverweightModal isModalOpen={isModalOwOpen} setIsModalOpen={setIsModalOwOpen} title="Sobrepeso" setUpdatePage={setUpdatePage} />

          {/* Title Start */}
          <div className="page-title-container">
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-5 mb-sm-0 me-auto">
                <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Atrás</span>
                </div>
                <h1 className="mb-5 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>
          {/* Title End */}
          {user !== null && (
            <Row>
              <Col xl="8">
                <Row>
                  {/* User Data */}
                  <Col xs="12" xl="12">
                    <h2 className="small-title">Usuario</h2>
                    <Card className="mb-5">
                      <Card.Body>
                        <Row className="g-0">
                          <Col xs="12" xl="9" className="d-flex align-items-center">
                            <h2 className="cta-1 text-primary mb-2">{user.legal_name}</h2>
                          </Col>
                          <Col xs="12" xl="3">
                            <div className="d-flex align-items-center justify-content-md-end mb-2">
                              {user.current_balance > 999 ? (
                                <div className="text-primary ms-2 pt-2 h2">
                                  <i className="d-inline-block icon-21 bi-emoji-sunglasses me-1" />
                                  <span className="text-small">$</span>
                                  {fmDecimal.from(parseInt(user.current_balance, 10))}
                                </div>
                              ) : (
                                <div className="text-primary ms-2 pt-2 h2">
                                  <i className="d-inline-block icon-21 bi-emoji-frown me-1" />
                                  <span className="text-small">$</span>
                                  {fmDecimal.from(parseInt(user.current_balance, 10))}
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-0">
                          <Col xs="12" className="bg-infox pt-md-5">
                            <Row>
                              <Col xs="12" md="7" className="bg-warningx d-flex flex-column justify-content-start">
                                <div className="d-flex align-items-center mb-2">
                                  <CsLineIcons icon="user" size="19" className="text-muted" />
                                  <div className="text-alternate ms-2">{user.crm_user_id}</div>
                                </div>

                                <div className="d-flex align-items-center mb-2">
                                  <CsLineIcons icon="invoice" size="19" className="text-muted" />
                                  <div className="text-alternate ms-2">{user.tax_id}</div>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                  <CsLineIcons icon="email" size="19" className="text-muted" />
                                  <div className="text-alternate ms-2">{user.email}</div>
                                </div>
                              </Col>
                              <Col xs="12" md="5" className="bg-successx d-flex flex-column justify-content-start">
                                <div className="d-flex align-items-center mb-2">
                                  <CsLineIcons icon="calendar" size="19" className="text-muted" />
                                  <div className="text-alternate ms-2">{`INGRESO ${moment(user.created).format('DD-MM-YYYY')}`}</div>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                  {user.api ? (
                                    <CsLineIcons icon="check-circle" size="19" className="text-muted" />
                                  ) : (
                                    <CsLineIcons icon="close-circle" size="19" className="text-muted" />
                                  )}
                                  <div className="text-alternate ms-2">{user.api ? 'MIGRADO' : 'NO MIGRADO'}</div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* Update Balance */}
                  <Col xs="12">
                    <h2 className="small-title">Modificar Saldo</h2>
                    <Card className="mb-5">
                      <Card.Body>
                        <Row className="g-0 bg-infof">
                          <Col xs="12" sm="auto" className="me-3 me-md-4">
                            <Button variant="primary" className="btn-icon btn-icon-start mb-4 mb-md-2  w-auto" onClick={() => setIsModalOwOpen(true)}>
                              <CsLineIcons icon="warning-hexagon" /> <span>Sobrepeso</span>
                            </Button>
                          </Col>
                          <Col xs="12" sm="auto" className="me-3 mx-md-4">
                            {/* p-0 pt-1 me-5 heading text-start */}
                            <Button variant="primary" className="btn-icon btn-icon-start mb-4 mb-md-2  w-auto" onClick={() => setIsModalIncreaseOpen(true)}>
                              <CsLineIcons icon="plus" /> <span>Agregar Saldo</span>
                            </Button>
                          </Col>
                          <Col xs="12" sm="auto" className="me-3 mx-md-4">
                            {/* p-0 pt-1 me-5 heading text-start */}
                            <Button variant="primary" className="btn-icon btn-icon-start mb-4 mb-md-2  w-auto" onClick={() => setIsModalReduceOpen(true)}>
                              <CsLineIcons icon="minus" /> <span>Cargo Adiccional</span>
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              {/* User Permissions */}
              <Col xl="4">
                <h2 className="small-title">Modificar Cuenta</h2>
                <EditUser user={user} setUpdatePage={setUpdatePage} />
              </Col>

              <Col xs="12">
                {/* Statements */}
                <Col xs="12">
                  <h2 className="small-title">Movimientos</h2>
                  <Card className="mb-5">
                    <Card.Body>
                      <Row className="g-0 mb-3">
                        <Col xs="12" sm="10" className="g-0">
                          <ul id="statement-nav" className="nav nav-pills">
                            <li className="nav-item">
                              <button type="button" className="nav-link active" name="statements" onClick={movementRenderer}>
                                Movimientos
                              </button>
                            </li>
                            <li className="nav-item">
                              <button type="button" className="nav-link" name="shippings" onClick={movementRenderer}>
                                Envíos
                              </button>
                            </li>
                            <li className="nav-item">
                              <button type="button" className="nav-link" name="overweights" onClick={movementRenderer}>
                                Sobrepesos
                              </button>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <Row>{movementHistory}</Row>
                    </Card.Body>
                  </Card>
                </Col>
                {/* Billings */}
                <Col xs="12">
                  <h2 className="small-title">Facturación</h2>
                  <Card className="mb-5">
                    <Card.Body>
                      <Row className="g-0 mb-5">
                        <Col xs="12" sm="10" className="g-0">
                          <ul id="billing-nav" className="nav nav-pills">
                            <li className="nav-item">
                              <button type="button" className="nav-link active" name="invoice" onClick={billingRenderer}>
                                Facturas
                              </button>
                            </li>
                            <li className="nav-item">
                              <button type="button" className="nav-link" name="receipts" onClick={billingRenderer}>
                                Recibos
                              </button>
                            </li>
                            <li className="nav-item">
                              <button type="button" className="nav-link" name="reps" onClick={billingRenderer}>
                                REP'S
                              </button>
                            </li>
                          </ul>
                        </Col>
                        <Col xs="12" sm="2" className="g-0 d-flex justify-content-end">
                          <NavLink to={`/users-all/billings/${crmUserId}/${user.legal_name}`} className="cursor-pointer text-decoration-underline">
                            Ver todos
                          </NavLink>
                        </Col>
                      </Row>
                      <Row>{billingHistory}</Row>
                    </Card.Body>
                  </Card>
                </Col>
                {/* Notes */}
                <Col xs="12">
                  <h2 className="small-title">Comentarios</h2>
                  <Card className="mb-5">
                    <Card.Body>
                      <Row className="g-0 mb-5">
                        {/* Write Commnet Start */}
                        <Col xs="12" lg="12" className="mx-auto pe-lg-3">
                          <div>
                            <Form.Floating className="mb-3">
                              <Form.Control
                                id="floatingInputCustom"
                                type="text"
                                placeholder="name@example.com"
                                value={newComment}
                                style={{ height: '100px' }}
                                onChange={(e) => {
                                  setNewComment(e.target.value);
                                }}
                              />
                              <label htmlFor="floatingInputCustom">Escribe un comentario</label>
                            </Form.Floating>
                            {!isEditing ? (
                              <Button variant="primary" className="btn-icon btn-icon-start mb-2 " onClick={handlerAddComment}>
                                <CsLineIcons icon="save" /> <span>Guardar</span>
                              </Button>
                            ) : (
                              <Button variant="primary" className="btn-icon btn-icon-start mb-2  w-auto" onClick={handlerEditComment}>
                                <CsLineIcons icon="edit" /> <span>Editar</span>
                              </Button>
                            )}
                          </div>
                        </Col>
                        {/* Write Commnet End */}
                        {/* List Commnet Start  style={{ height: '350px' }} */}
                        <Col xs="12" lg="12" className="mx-auto mx-0">
                          {/* <Application theme={theme}></Application> */}
                          <Modal
                            isOpen={isModalDeleteOpen.value}
                            onRequestClose={() => setIsModalDeleteOpen({ value: false, id: '' })}
                            borderRadius="rounded"
                            // title=""
                            footer={
                              <div className="d-flex justify-content-end">
                                <Button variant="outline-primary" onClick={() => setIsModalDeleteOpen({ value: false, id: '' })}>
                                  Cancelar
                                </Button>
                                <Button variant="primary" className="ms-2" onClick={() => handlerDeleteComment(isModalDeleteOpen.id)}>
                                  Eliminar
                                </Button>
                              </div>
                            }
                          >
                            <h2 className="fs-6 text-center my-4">¿Quieres eliminar este comentario?</h2>
                          </Modal>
                          <div
                            className={`${
                              isLoadingComments ? 'd-block' : 'd-none'
                            }  d-flex justify-content-center align-items-center position-absolute start-50 top-50`}
                          >
                            <LoadingRed />
                          </div>

                          <div className={`${isLoadingComments && 'opacity-25'} position-relative`}>
                            {/* list comments */}
                            {comments?.map((comment, index) => (
                              <Card className="mb-2 px-0 " key={index}>
                                {/* sh-lg-8 */}
                                <Card.Body className="py-3 px-4 sh-auto">
                                  <Row className="g-0 h-100 d-flex align-items-center cursor-default">
                                    <Col xs="12" className="d-flex flex-column justify-content-center my-2 order-3 position-relative">
                                      <div className="p-0 pe-2 h-100 d-flex align-items-center font-weight-bold">{comment.content}</div>
                                    </Col>
                                    <Col xs="12" className="d-flex flex-column justify-content-center mb-2  order-4  position-relative">
                                      <div className="p-0 pe-2 h-100 d-flex align-items-center">{`${moment(comment.created).format('DD-MM-YYYY')}`}</div>
                                    </Col>
                                    <Col xs="10" className="order-1">
                                      {comment.staff}
                                    </Col>
                                    <Col xs="2" className="order-2 d-flex align-items-center justify-content-end ">
                                      <span onClick={() => setValuesToEdit({ value: comment.content, id: comment.id })}>
                                        <CsLineIcons icon="edit" size="18" className="anchor-effect-primary me-2" />
                                      </span>
                                      <span onClick={() => setIsModalDeleteOpen({ value: true, id: comment.id })}>
                                        <CsLineIcons icon="bin" size="18" className="anchor-effect-primary" />
                                      </span>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            ))}
                            {/* paginate */}
                            <div
                              className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${
                                totalPages === 0 || (totalItems < 20 && 'd-none')
                              }`}
                            >
                              <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
                            </div>
                          </div>
                        </Col>
                        {/* List Commnet End */}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default UserDetail;
