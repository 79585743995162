import React from 'react';
import { CSVLink } from 'react-csv';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// external libraries
import moment from 'moment';
import { toUpperIfString } from '../../utils/textFormat';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});
const ShippingCSV = ({ shippings, crmId }) => {
  // console.log(shippings)
  const underscoresRegex = /_+/g;
  // - Format SelectSupplier
  // funcion temporal para arreglar el problema con epack que no se muestre a los clientes
  const formatSelectSupplier = (supplierName) => {
    switch (supplierName) {
      case 'epackExpress':
        return 'DHLExpressB';
      case 'dhl_epackExpress':
        return 'DHLExpressB';
      case 'dhl_epackEconomico':
        return 'DHLEconomicoB';
      case 'epackEconomico':
        return 'DHLEconomicoB';
      case 'epackDiaSiguiente':
        return 'EstafetaDiaSiguienteB';
      case 'epackTerrestre':
        return 'EstafetaTerrestreB';
      case 'epackEconomicoD':
        return 'FedExEconomicoD';
      case 'epackExpressD':
        return 'FedExExpressD';
      default:
        return supplierName;
    }
  };
  const csvData = shippings?.map((shipment) => ({
    date: moment(shipment.creation_date).format('DD-MM-YY'),
    time: shipment.creation_date.slice(11, 16),
    // labelId: shipment.shipping_label_id ? `${shipment.shipping_label_id}` : 'sin guia',
    senderName: toUpperIfString(shipment.address_from.name),
    senderPhone: shipment.address_from.phone,
    senderStreet: toUpperIfString(shipment.address_from.street),
    senderNumber: shipment.address_from.street_number,
    senderNeighborhood: toUpperIfString(shipment.address_from.neighborhood),
    zipFrom: shipment.address_from.zip_code,
    senderCity: toUpperIfString(shipment.address_from.city),
    senderState: toUpperIfString(shipment.address_from.state),
    referenceFrom: toUpperIfString(shipment.address_from.reference),
    reciverName: toUpperIfString(shipment.address_to.name),
    reciverPhone: shipment.address_to.phone,
    reciverStreet: toUpperIfString(shipment.address_to.street),
    reciverNumber: shipment.address_to.street_number,
    reciverNeighborhood: toUpperIfString(shipment.address_to.neighborhood),
    zipTo: shipment.address_to.zip_code,
    reciverCity: toUpperIfString(shipment.address_to.city),
    reciverState: toUpperIfString(shipment.address_to.state),
    referenceTo: toUpperIfString(shipment.address_to.reference),
    parcelDescription: toUpperIfString(shipment.parcel.product_description),
    serviceCode: formatSelectSupplier(shipment.shipping_code).replace(underscoresRegex, ' '),
    monto: shipment.shipping_label_id ? fmDecimal.from(shipment.shipping_rates.total, { symbol: '$' }) : 0,
    ...shipment,
  }));
  const headers = [
    { label: 'Fecha', key: 'date' },
    { label: 'Hora', key: 'time' },
    { label: 'Guia', key: 'shipping_label_id' },
    { label: 'Nombre Origen', key: 'senderName' },
    { label: 'Telefono Origen', key: 'senderPhone' },
    { label: 'Calle Origen', key: 'senderStreet' },
    { label: 'Numero Origen', key: 'senderNumber' },
    { label: 'Colonia Origen', key: 'senderNeighborhood' },
    { label: 'Código Postal Origen', key: 'zipFrom' },
    { label: 'Ciudad Origen', key: 'senderCity' },
    { label: 'Estado Origen', key: 'senderState' },
    { label: 'Referencias Origen', key: 'referenceFrom' },
    { label: 'Nombre Destino', key: 'reciverName' },
    { label: 'Telefono Destino', key: 'reciverPhone' },
    { label: 'Calle Destino', key: 'reciverStreet' },
    { label: 'Numero Destino', key: 'reciverNumber' },
    { label: 'Colonia Destino', key: 'reciverNeighborhood' },
    { label: 'Código Postal Destino', key: 'zipTo' },
    { label: 'Ciudad Destino', key: 'reciverCity' },
    { label: 'Estado Destino', key: 'reciverState' },
    { label: 'Referencias Destino', key: 'referenceTo' },
    { label: 'Paquete', key: 'parcelDescription' },
    { label: 'Largo', key: 'parcel.depth' },
    { label: 'Ancho', key: 'parcel.width' },
    { label: 'Alto', key: 'parcel.height' },
    { label: 'Peso Físico', key: 'parcel.weight' },
    { label: 'Peso Volumetrico', key: 'parcel.volumetric_weight' },
    { label: 'Servicio', key: 'serviceCode' },
    // { label:'Detalle', key: 'detailSupplier'},
    { label: 'Cargo Zonificacion', key: 'shipping_rates.zonification' },
    { label: 'Zona Extendida', key: 'shipping_rates.extended_area' },
    { label: 'Combustible', key: 'shipping_rates.fuel' },
    { label: 'Cargo seguro', key: 'shipping_rates.insurance' },
    { label: 'Costo', key: 'monto' },
  ];
  return (
    <CSVLink data={csvData} headers={headers} className="btn btn-primary" filename={`Historial Envíos ${crmId}.csv`} style={{ height: '36px' }} target="_blank">
      <span>
        <CsLineIcons icon="download" size="15" className="mb-1" /> Excel{' '}
      </span>
    </CSVLink>
  );
};
export default ShippingCSV;
