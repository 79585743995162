const base64ToFile = (base64String, fileName) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Uint8Array(byteCharacters.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new File([byteNumbers], fileName, { type: 'application/pdf' });
};
export { base64ToFile };
