import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// external libraries
import moment from 'moment';

const PickupCard = ({ pickup }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col xs="auto" className="positio-relative">
          <NavLink to={`/envios/historial/detalle/${pickup.shipping_id}`}>
            <img src={pickup.service_img} alt="servicio" className="card-img card-img-horizontal sw-11 h-100" />
          </NavLink>
        </Col>
        <Col className="py-4 py-lg-0 ps-3 pe-2 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" lg="6" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center">
              <div className="text-muted text-small">Servicio / ID Recolección</div>
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
                <NavLink to={`/envios/historial/detalle/${pickup.shipping_id}`}>{pickup.shipping_code}</NavLink>
              </OverlayTrigger>
              <div className="text-medium text-muted text-truncate">{pickup.pickup_id}</div>
            </Col>
            <Col xs="6" lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center  align-items-lg-center order-2">
              <div className="text-muted text-small">Fecha</div>
              <div className="lh-1 text-alternate">{moment(pickup.pickup_date).format('DD-MM-YY')}</div>
            </Col>
            <Col xs="4" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-end justify-content-center align-items-lg-center order-3">
              <div className="text-muted text-small">Paq</div>
              {/* align-items-lg-center */}
              <div className="lh-1 text-alternate">{`${pickup.quantity}`}</div>
            </Col>
            <Col
              xs="2"
              lg="2"
              className="d-flex flex-column mb-2 mb-lg-0 align-items-center order-4 order-lg-last justify-content-end justify-content-lg-center"
            >
              <div className="text-muted text-small">Kg</div>

              <div className="lh-1 text-alternate">{pickup.total_weight}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default PickupCard;
